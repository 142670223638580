import React, { Component } from 'react'

import logoVisa from 'Assets/images/svg/cards_visa.svg'
import logoMastercard from 'Assets/images/svg/cards_mastercard.svg'
import logoCartBlancare from 'Assets/images/svg/cards_cb.svg'
import logoAmericanExpress from 'Assets/images/svg/cards_amex.svg'
import logoTotal from 'Assets/images/svg/cards_total.svg'
import logoCommonCard from 'Assets/images/svg/cards_common.svg'

export class CardLogo extends Component {
  render () {
    const { brand } = this.props

    switch (brand) {
      case 'visa': return <img src={logoVisa} alt='logo for Visa credit card' />
      case 'mastercard': return <img src={logoMastercard} alt='logo for Mastercard credit card' />
      case 'CB': return <img src={logoCartBlancare} alt='logo for a BlanCare credit card' />
      case 'cartes_bancaires': return <img src={logoCartBlancare} alt='logo for BlanCare credit card' />
      case 'amex': return <img src={logoAmericanExpress} alt='logo for American Express credit card' />
      case 'total': return <img src={logoTotal} alt='logo for TotalFleet credit card' />
      default: return <img src={logoCommonCard} alt='logo for a common credit card' />
    }
  }
}
