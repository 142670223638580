import Loader from 'Components/Loader'
import ModalOp from 'Components/ModalOp'
import React, { Component } from 'react'
import SvgImage from 'Components/SvgImage'
import { FormattedMessage } from 'react-intl'
import { fetchPaymentMethodState, transitionPaymentMethodToError } from 'Helpers/paymentMethodsHelper'
import iconLightBlue from 'ClientAssets/images/icon-light-blue.svg'
import iconLightGreen from 'ClientAssets/images/icon-light-green.svg'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const PENDING_PAYMENT_PULLER_ID = 'pending-payment-puller'
const PERIODIC_PULL_PERIOD = 2000 // in milliseconds

export class MonextAddPaymentMethodPopup extends Component {
  static propTypes = {
    theme: PropTypes.oneOf(['blue', 'green']),
    pendingToken: PropTypes.string,
    onSuccess: PropTypes.func,
    onError: PropTypes.func
  }

  static defaultProps = {
    theme: 'green'
  }

  initialMessagesTimings = () => {
    if (process.env.PENDING_METHOD_CAROUSEL_TIMINGS_CSV === undefined) return [25, 10, 10, 15]
    return process.env.PENDING_METHOD_CAROUSEL_TIMINGS_CSV.toString()
      .split(',')
      .map(Number)
      .filter(Number.isFinite)
  }

  state = {
    currentCarouselMessage: null,
    nextMessages: ['message1', 'message2', 'message3', 'message4'],
    nextMessagesTimings: this.initialMessagesTimings(), // in seconds
    showNextMessageTimerId: null,
    fetchStateFromBackendIntervalId: null,
    pullingStopped: false
  }

  showNextMessage = async () => {
    if (this.state.pullingStopped) return

    const noMoreMessagesToDisplay = this.state.nextMessages.length === 0 || this.state.nextMessagesTimings.length === 0
    if (noMoreMessagesToDisplay) {
      this.closePopup()
      this.props.onError('pending_payment_stopped_waiting')
      await transitionPaymentMethodToError(this.props.pendingToken)
      return
    }

    const nextTimer = setTimeout(this.showNextMessage, this.state.nextMessagesTimings[0] * 1000)
    this.setState((previousState) => ({
      currentCarouselMessage: previousState.nextMessages[0],
      nextMessages: previousState.nextMessages.slice(1),
      nextMessagesTimings: previousState.nextMessagesTimings.slice(1),
      timerShowNextMessage: nextTimer
    }))
  }

  componentDidMount () {
    this.setState({
      fetchStateFromBackendIntervalId: setInterval(this.pullPaymentMethodState, PERIODIC_PULL_PERIOD),
      showNextMessageTimerId: setTimeout(this.showNextMessage, 0)
    })
    this.showPopup()
  }

  showPopup () { $(`#${PENDING_PAYMENT_PULLER_ID}`).modal('show') }

  closePopup () {
    if (this.state.fetchStateFromBackendIntervalId) clearInterval(this.state.fetchStateFromBackendIntervalId)
    if (this.state.showNextMessageTimerId) clearTimeout(this.state.showNextMessageTimerId)
    this.setState({ pullingStopped: true })
    $(`#${PENDING_PAYMENT_PULLER_ID}`).modal('hide')
  }

  pullPaymentMethodState = async () => {
    if (this.state.pullingStopped) return

    const paymentMethodAttributes = await fetchPaymentMethodState({ monextWidgetToken: this.props.pendingToken })
    if (paymentMethodAttributes.paymentMethodState === 'error') {
      this.closePopup()
      this.props.onError(paymentMethodAttributes.paymentMethodErrorId || 'pending_payment_stopped_waiting')
      return
    }
    if (paymentMethodAttributes.paymentMethodState === 'finished') {
      this.closePopup()
      if (this.props.onSuccess) this.props.onSuccess()
    }
    // else means it's 404 or connection/network timeout or state is pending - in this case we just don't stop the timers.
  }

  render () {
    const { theme } = this.props

    const isGreen = theme === 'green'
    const isBlue = theme === 'blue'

    const parentClassName = classNames('pending-payment-method-popup', {
      'pending-payment-method-popup--blue': isBlue
    })

    const iconLight = isGreen ? iconLightGreen : iconLightBlue

    return (
      <ModalOp id={PENDING_PAYMENT_PULLER_ID} centered hideCloseIcon preventClosingOnEscape preventClosingOnClickOutside
        additionalModalClass={parentClassName}
      >
        <div className='pending-payment-method-popup__wrapper'>
          <div className='pending-payment-method-popup__title'>
            <FormattedMessage id='pending_payment_methods.popup.spinner_title' />
          </div>

          <div className='pending-payment-method-popup__loader'>
            <Loader green={isGreen} blue={isBlue} />
          </div>

          <div className='pending-payment-method-popup__lower-part'>
            <div className='pending-payment-method-popup__divider' />

            <div className='pending-payment-method-popup__bulb'>
              <SvgImage svg={iconLight} />
            </div>

            {this.state.currentCarouselMessage &&
              <div className='pending-payment-method-popup__carousel-message'>
                <FormattedMessage id={`pending_payment_methods.popup.carousel.${this.state.currentCarouselMessage}`} />
              </div>
            }
          </div>
        </div>
      </ModalOp>
    )
  }
}
