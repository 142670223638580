import classNames from 'classnames'
import logoCommonGrey from 'Assets/images/svg/cards_common-grey.svg'
import logoCommonGreen from 'Assets/images/svg/cards_common-green.svg'
import logoCommonBlue from 'Assets/images/svg/cards_common-blue.svg'
import logoCommon from 'Assets/images/svg/cards_common.svg'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

export class StripeRadioButton extends Component {
  retrieveLogoSrc = () => {
    if (this.props.theme === 'blue') return logoCommonBlue
    else if (this.props.theme === 'green') return logoCommonGreen

    return logoCommon
  }

  render () {
    const logoSrc = this.retrieveLogoSrc()

    return (
      <div
        className={classNames(
          'payment-method-form__header',
          {
            'payment-method-form__header--disabled': this.props.disabled,
            'payment-method-form__header--selected': this.props.selected
          }
        )}
      >
        <input type='radio' name='payment-service-name'
          className='payment-method-form__radiobutton payment-method-form__radiobutton--prepended'
          defaultChecked={this.props.selected} ref={this.props.radioButtonRef} />

        {this.props.selected && <img src={logoSrc} alt='logo for Common credit card' className='payment-method-form__card-logo' />}
        {!this.props.selected && <img src={logoCommonGrey} alt='logo for Common credit card' className='payment-method-form__card-logo' />}

        <FormattedMessage id='authentication.registration.payment_info.add_new_card'>
          {(message) => (
            <span className={classNames('payment-method-form__radiobutton-label',
              { 'payment-method-form__radiobutton-label--selected': this.props.selected })}>
              {message}
            </span>
          )}
        </FormattedMessage>
      </div>
    )
  }
}
