import classNames from 'classnames'
import { scrollToElementWithFooterAdjustment } from 'Helpers/form'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

const CSS_HREF = 'https://payment.cdn.payline.com/cdn/styles/widget-min.css'
function javascriptSourceUrl () {
  switch (process.env.DEPLOYMENT_STAGE) {
    case undefined:
      Sentry.captureException(new Error('DEPLOYMENT_STAGE is not defined, default to production'))
      return 'https://payment.cdn.payline.com/cdn/scripts/widget-min.js'
    case 'production':
      return 'https://payment.cdn.payline.com/cdn/scripts/widget-min.js'
    default:
      return 'https://homologation-payment.cdn.payline.com/cdn/scripts/widget-min.js'
  }
}

class MonextForm extends Component {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    monextWidgetToken: PropTypes.string.isRequired,
    localePrefix: PropTypes.string.isRequired,
    errorMessageId: PropTypes.string
  }

  state = {
    loadingCSS: true,
    loadingJS: true,
    loadingWidget: true
  }

  paylineWidgetRef = React.createRef()
  footerRef = React.createRef()

  replaceTotalFormLabels = (stateObject) => {
    if (stateObject.state === 'PAYMENT_METHODS_LIST') {
      this.setState({ loadingWidget: false })
    }
    const ctaButton = $('.pl-pay-btn')
    ctaButton.attr('data-automation-id', 'register-card__submit-button--monext')
    const translatedMessage = this.props.intl.formatMessage({ id: 'authentication.continue_booking' })
    ctaButton.text(translatedMessage)
    scrollToElementWithFooterAdjustment(this.paylineWidgetRef.current, this.footerRef.current)
  }

  loadWidgetScripts () {
    window['PaylineBeforeDisplayingTheForm'] = this.replaceTotalFormLabels
    window['PaylineBeforeSubmittingTheForm'] = () => {
      Payline.Api.getTokenStatus(Payline.Api.getToken(), ({ tokenStatus }) => {
        if (tokenStatus === 'EXPIRED') {
          this.props.onError('form_expired')
        }
      })
      return true
    }
  }

  attemptFetch (url, onSuccess, retryIntervalMilliseconds) {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), retryIntervalMilliseconds)

    return fetch(url, { signal: controller.signal })
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok')

        return response.text()
      })
      .then((innerText) => {
        clearTimeout(timeoutId)
        return onSuccess(innerText)
      })
      .catch(error => {
        Sentry.captureException(error)
        setTimeout(this.attemptFetch(url, onSuccess, retryIntervalMilliseconds), retryIntervalMilliseconds)
      })
  }

  loadJsWithFetch (url, retryIntervalMilliseconds = 3000) {
    const onSuccess = (js) => {
      const script = document.createElement('script')
      script.textContent = js
      document.body.appendChild(script)
      this.setState({ loadingJS: false })
    }
    return this.attemptFetch(url, onSuccess, retryIntervalMilliseconds)
  }

  loadCssWithFetch (url, retryIntervalMilliseconds = 3000) {
    const onSuccess = (css) => {
      const style = document.createElement('style')
      style.textContent = css
      const head = document.head
      head.insertBefore(style, head.firstChild)
      this.setState({ loadingCSS: false })
      setTimeout(() => this.loadJsWithFetch(javascriptSourceUrl()), 0)
    }
    return this.attemptFetch(url, onSuccess, retryIntervalMilliseconds)
  }

  componentDidMount () {
    this.loadWidgetScripts()
    setTimeout(() => this.loadCssWithFetch(CSS_HREF), 0)
  }

  componentWillUnmount () {
    if (typeof Payline !== 'undefined' && typeof Payline.Api !== 'undefined') Payline.Api.endToken()
    if (typeof window === 'undefined') return
    if (typeof window.delete === 'undefined') return

    window.delete('PaylineBeforeDisplayingTheForm')
    window.delete('PaylineBeforeSubmittingTheForm')
  }

  render () {
    const loading = this.state.loadingCSS || this.state.loadingJS || this.state.loadingWidget

    return (<>
      {loading &&
        <div
          className='purchase-summary__block--flex purchase-summary__block--bigger-svg purchase-summary__block--centered'>
          {this.props.renderLoader(false)}
        </div>}

      <div className={classNames('widget_wrapper', { hidden: loading })}>
        {this.props.errorMessageId &&
          <div className='payment-info-panel__error-message payment-info-panel__error-message--orange'>
            <FormattedMessage
              id={`pending_payment_methods.errors.${this.props.errorMessageId}`}
              defaultMessage={this.props.intl.formatMessage({ id: 'pending_payment_methods.errors.common_message' })}
            />
          </div>
        }
        <div
          id='PaylineWidget' data-token={this.props.monextWidgetToken} data-template='column'
          data-embeddedredirectionallowed data-auto-init
          data-event-didshowstate='PaylineBeforeDisplayingTheForm'
          data-event-beforepayment='PaylineBeforeSubmittingTheForm'
          ref={this.paylineWidgetRef}
        />
        <div className='payment-info-panel__legal-notice' ref={this.footerRef}>
          <FormattedMessage id='payment_method.legal_terms_before_link' />
          <a href={`${this.props.localePrefix}/privacy-policy`}>
            <FormattedMessage id='payment_method.legal_terma_link' />
          </a>
        </div>
      </div>
    </>)
  }
}

export default injectIntl(MonextForm)
