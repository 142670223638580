import iconQuestionMark from 'Assets/images/svg/icon-question-mark-circled.svg'
import classNames from 'classnames'
import logoTotal from 'Assets/images/svg/cards_total.svg'
import { FloatingPopover } from 'Components/FloatingPopover'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

export class MonextRadioButton extends Component {
  render () {
    const componentClassName = classNames(
      'payment-method-form__component', { 'payment-method-form__component--disabled': this.props.disabled }
    )

    return (
      <div
        className={classNames(
          'payment-method-form__header payment-method-form__header--monext',
          {
            'payment-method-form__header--selected': this.props.selected,
            'payment-method-form__header--disabled': this.props.disabled
          }
        )}
      >
        <div className={componentClassName}>
          <input type='radio' name='payment-service-name'
            className='payment-method-form__radiobutton payment-method-form__radiobutton--prepended'
            disabled={this.props.disabled} defaultChecked={this.props.selected} ref={this.props.radioButtonRef} />
        </div>

        <div className={componentClassName}>
          <img src={logoTotal} alt='logo for TotalFleet credit card' className='payment-method-form__card-logo' />
        </div>

        <div className={`${componentClassName} payment-method-form__component--padded-left`}>
          <FormattedMessage id='authentication.registration.payment_info.add_new_total_fleet_card'>
            {(message) => (
              <span className={classNames('payment-method-form__radiobutton-label',
                { 'payment-method-form__radiobutton-label--selected': this.props.selected })}>
                {message}
              </span>
            )}
          </FormattedMessage>
        </div>

        {this.props.disabled &&
          <div className='payment-method-form__tooltip'>
            <FloatingPopover
              paymentMethodId={'new_payment_method'}
              tooltipIcon={iconQuestionMark}
              messageId='purchase.purchase_summary.unavailable_payment_method_toolbox'
            />
          </div>
        }
      </div>
    )
  }
}
