import iconQuestionMark from 'Assets/images/svg/icon-question-mark-circled.svg'
import classNames from 'classnames'
import { FloatingPopover } from 'Components/FloatingPopover'
import React, { Component, createRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { CardLogo } from './CardLogo'
import { cardNumberView, formatDate } from './helpers'

export class SummaryPaymentMethodCard extends Component {
  radioButtonRef = createRef()

  onRowClick = () => {
    if (!this.props.isEnabled) return

    this.props.onSelect(this.props.id, this.props.allowsThreeds)
    this.radioButtonRef.current.click()
  }
  render () {
    const isSelected = this.props.selectedPaymentMethodId === this.props.id
    const componentCssClass = classNames('summary-payment-method-card__component',
      {
        'summary-payment-method-card__component--disabled': !this.props.isEnabled,
        'summary-payment-method-card__component--selected': isSelected
      })
    return (
      <div
        className={classNames('summary-payment-method-card',
          {
            'summary-payment-method-card--selected': isSelected,
            'summary-payment-method-card--disabled': !this.props.isEnabled
          })}
        onClick={this.onRowClick}
      >
        <div className={componentCssClass}>
          <input type='radio' name='payment-method-id' className='payment-info-panel__radiobutton'
            disabled={!this.props.isEnabled}
            defaultChecked={isSelected}
            value={this.props.id} data-automation-id={`payment-method-${this.props.id}`}
            onChange={this.props.onChange}
            ref={this.radioButtonRef}
          />
        </div>
        <div className={`${componentCssClass} summary-payment-method-card__component--width40`}>
          <CardLogo brand={this.props.displayBrand || this.props.brand} />
        </div>
        <div className={`${componentCssClass} summary-payment-method-card__component--width70`}>
          {cardNumberView(this.props.cardNumber.toString())}
        </div>
        <div className={componentCssClass}>
          <FormattedMessage id='purchase.purchase_summary.format_expires_at_short' >
            {format => formatDate(this.props.expirationDate, format)}
          </FormattedMessage>
        </div>

        {!this.props.isEnabled &&
          <div className='summary-payment-method-card__component summary-payment-method-card__component--right-aligned'>
            <FloatingPopover
              paymentMethodId={this.props.paymentMethodId}
              openedToolTipId={this.props.openedToolTipId}
              onToolTipOpened={this.props.onToolTipOpened}
              tooltipIcon={iconQuestionMark}
              messageId='purchase.purchase_summary.unavailable_payment_method_toolbox'
            />
          </div>
        }
      </div>
    )
  }
}
