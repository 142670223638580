import { StripeAddPaymentMethodPopup } from 'Components/StripeAddPaymentMethodPopup'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PaymentInfoPanel from '../Purchase/PaymentInfoPanel'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

class EditPaymentMethodPage extends Component {
  static propTypes = {
    stripePublicKey: PropTypes.string.isRequired,
    stripeIdentityId: PropTypes.number.isRequired
  }

  state = {
    paymentMethodErrorId: null
  }

  onRegisterPaymentMethodSuccess = () => {
    window.location = `${this.props.localePrefix}/payment/sources?registered=true`
  }

  onStripePendingPaymentMethodFailed = () => {
    this.setState({ paymentMethodErrorId: 'pending_payment_stopped_waiting' })
  }

  render () {
    const { intl: { formatMessage }, stripePublicKey, stripeIdentityId } = this.props

    return (
      <div className='edit-payment-method-page'>
        <StripeAddPaymentMethodPopup
          theme='blue'
          onFetchSuccess={this.onRegisterPaymentMethodSuccess}
          onFetchTimeoutError={this.onStripePendingPaymentMethodFailed}
        />

        <a
          href={`${this.props.localePrefix}/payment/sources`}
          className='profile-payment-methods-list__return-to-list-button profile-payment-methods-list__return-to-list-button--desktop'
        >
          {formatMessage({ id: 'pages.credit_card.profile.actions.back' })}
        </a>
        <div className='box box-shadow-custom profile-payment-methods-list__forms-container'>
          <h3>{formatMessage({ id: 'pages.credit_card.profile.edit_form_title' })}</h3>
          <PaymentInfoPanel
            previousBillingIdentityId={stripeIdentityId}
            monextPaymentMethodAllowed={false}
            callingPage={'profile'}
            stripePublicKey={stripePublicKey}
            errorMessageId={this.state.paymentMethodErrorId}
            hideTitle
            hideMonextForm
          />
        </div>
        <a
          href={`${this.props.localePrefix}/payment/sources`}
          className='profile-payment-methods-list__return-to-list-button profile-payment-methods-list__return-to-list-button--mobile'
        >
          {formatMessage({ id: 'pages.credit_card.profile.actions.back' })}
        </a>
      </div>
    )
  }
}

const mapStateToProps = ({
  railsContext: { currentLocale }
}) => ({ currentLocale })

export default injectIntl(connect(mapStateToProps)(EditPaymentMethodPage))
