import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import SearchParks from './SearchParks'
import PlaceChildrenPlacesItem from './PlaceChildrenPlacesItem'
import BreadCrumb from '../components/BreadCrumb'
import ReassurancePanel from '../components/BlockSections/ReassurancePanel'
import AndAlsoSection from '../components/BlockSections/AndAlsoSection'
import ModalOp from '../components/ModalOp'
import NearbyPlacesSection from '../components/BlockSections/NearbyPlacesSection'
import TopicsSection from '../components/BlockSections/TopicsSection'
import OnMapSection from '../components/BlockSections/OnMapSection'
import { connect } from 'react-redux'
import { getMapWrapperComponent } from '../../../libs/map'
import { getDiscount } from '../helpers/park'
import { FiltersPanel } from '../components/FiltersPanel'
import { datalayerPushNavigationEvent } from '../helpers/application'

const TABLET_PORTRAIT_UPPER_BOUNDARY = 992

class Place extends Component {
  state = {
    showOverlay: false
  }

  componentDidMount = () => {
    const { parks } = this.props.parks
    window.addEventListener("resize", this.handleResize)

    const lastVisit = localStorage.getItem("lastVisit")
    const now = moment()
    const isFirstVisit = !lastVisit
    const isPastThirtyDays = lastVisit && now.isAfter(moment(lastVisit).add(30, 'days'))
    const showOverlay = isFirstVisit || isPastThirtyDays

    if (showOverlay) {
      localStorage.setItem("lastVisit", now)
    }

    this.setState({ showOverlay })
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = (e) => {
    const onMapModal = document.querySelector('#react-on-map-modal')

    if (
      onMapModal &&
      onMapModal.classList &&
      onMapModal.classList.contains('in') &&
      e.target.outerWidth <= TABLET_PORTRAIT_UPPER_BOUNDARY + 1
    ) {
      $('#react-on-map-modal').modal('hide')
    }
  }

  handleOpenMapModal = (e) => {
    datalayerPushNavigationEvent('see_on_map')
    $('#react-on-map-modal').modal('show')
  }

  handleCloseMapModal = () => {
    $('#react-on-map-modal').modal('hide')
  }

  render () {
    const {
      bookableParks,
      breadCrumbItems,
      clusters,
      country,
      defaultImage,
      isUserOnMobile,
      lat,
      lng,
      type,
      localePrefix,
      parks,
      filteredParks,
      place,
      poiPlaces,
      zoom,
      canShowBackdrop
    } = this.props

    const refDescription = React.createRef()
    const scrollToDescription = () => {
      if (refDescription.current) {
        window.scrollTo({
          top: refDescription.current.offsetTop - 200,
          behavior: 'smooth'
        })
      }
    }

    return (
      <div className='react-component react-component--place'>

        <ModalOp id='react-on-map-modal' desktopOnly onModalClosed={this.handleCloseMapModal}>
          {
            React.createElement(
              getMapWrapperComponent(),
              {
                defaultCenter: { lat, lng },
                defaultZoom: zoom,
                clusters: clusters,
                parks: bookableParks,
                zoomControl: false
              }
            )
          }
        </ModalOp>

        <div className='container container--wide'>
          <div className='row'>
            {place.showFilters && !isUserOnMobile &&
              <div className='col-xs-12 col-sm-12 col-md-3'>
                <FiltersPanel showAllFilters={place.isAirport} automateFilters />
              </div>
            }

            <div className='col-xs-12 col-sm-12 col-md-6'>
              <div className='place__header'>
                {breadCrumbItems && !isUserOnMobile &&
                  <div className='place__breadcrumb'>
                    <BreadCrumb items={breadCrumbItems} />
                  </div>
                }

                <div className='place__title '>
                  <h1>{place.seoHeading}
                    {type === 'subscription' &&
                    <span>
                      &nbsp;- <FormattedMessage id='pages.place.subscription_subtitle' />
                    </span>
                    }
                  </h1>
                  {filteredParks.length > 0 &&
                    <span className='hidden-md hidden-lg'>
                      &nbsp;- <FormattedMessage id='pages.place.found_parks' values={{ n: filteredParks.length }} />
                    </span>
                  }
                </div>

                {place.shortDescription &&
                  <div className='place__short-description'>
                    <div dangerouslySetInnerHTML={{ __html: place.shortDescription }} />
                    &nbsp;
                    <a onClick={scrollToDescription}>
                      <span>
                        [<FormattedMessage id='pages.place.short_description.read_more' />]
                      </span>
                    </a>
                  </div>
                }
              </div>

              {parks && parks.length > 0 && <SearchParks place={place} refDescription={refDescription} isUserOnMobile={isUserOnMobile} />}
            </div>

            {parks && parks.length > 0 &&
              <div className='col-xs-12 col-sm-12 col-md-3 place__sidebar'>
                <OnMapSection className='panel hidden-xs hidden-sm' onClick={this.handleOpenMapModal} />
                <NearbyPlacesSection className='panel panel-default' placeNameTag='span' imgWidthDesktop='150' imgHeightDesktop='150' imgWidthMobile='280' imgHeightMobile='280' />
                <ReassurancePanel country={country} isSubscription={type === 'subscription'} />
                {poiPlaces && poiPlaces.length > 0 &&
                  <AndAlsoSection andAlsoLinks={poiPlaces} className='panel panel-default' inline={false} />
                }
                <TopicsSection className='panel panel-default' />
              </div>
            }
          </div>

          {place.childrenPlace &&
            <div className='place__children-places'>
              {place.childrenPlace.map((place, i) => (
                <div className='place__children-places__item' key={i}>
                  <PlaceChildrenPlacesItem
                    defaultImage={defaultImage}
                    index={i}
                    localePrefix={localePrefix}
                    place={place}
                  />
                </div>
              ))}
            </div>
          }

          {parks && parks.length === 0 && poiPlaces && poiPlaces.length > 0 &&
            <div className='row hidden-xs hidden-sm'>
              <div className='col-md-4'>
                <AndAlsoSection andAlsoLinks={poiPlaces} className='panel panel-default' inline={false} />
              </div>
            </div>
          }

          {breadCrumbItems && isUserOnMobile && (
            <div className='place__breadcrumb'>
              <BreadCrumb items={breadCrumbItems} />
            </div>
          )}

          {parks.some(park => getDiscount(park).discountRate < 0) &&
            <div className='place__discount-disclaimer'>
              <FormattedMessage id='pages.park.discount_disclaimer' />
            </div>
          }

          {
            place.subtitle && (
              <div className='place__description hidden-md hidden-lg'>
                <span className='place-description-subtitle'>{place.subtitle}</span>
              </div>
            )
          }

          {place.showDescription &&
            <div className='place__description' ref={refDescription}>
              <div dangerouslySetInnerHTML={{ __html: place.description }} />
              {place.topic &&
                <div className='place__description__topic'>
                  <br />
                  <FormattedMessage id='pages.place.associated_guide' />
                  <a href={place.topic.url}>
                    <FormattedMessage id='pages.place.associated_guide_link' values={{ name: place.topic.name }} />
                  </a>
                </div>
              }
            </div>
          }
          {this.state.showOverlay && canShowBackdrop && parks && parks.length > 0 && !isUserOnMobile &&
            <div className='place-backdrop fade in' onClick={(e) => { e.target.hidden = true }} />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  railsContext: {
    currentSite: { iso: country }
  },
  search: { clusters, parks, params: { lat, lng, type }, zoom, bookableParks, filteredParks },
  resources: { localePrefix, defaultImage }
}) => {
  return {
    bookableParks,
    clusters,
    country,
    defaultImage,
    lat,
    lng,
    type,
    localePrefix,
    parks,
    filteredParks,
    zoom
  }
}

export default connect(mapStateToProps)(Place)
