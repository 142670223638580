import 'intersection-observer' // polyfill for IE11, Safari, iOS browsers
import smoothscroll from 'smoothscroll-polyfill' // polyfill for IE11, Edge, Safari, iOS browsers
import 'whatwg-fetch' // polyfill for IE11
import moment from 'moment-timezone'
import React from 'react'
import ReactOnRails from 'react-on-rails'
import { compose } from 'redux'

import AccountCreditCardPaymentInfo from '../components/AccountCreditCardPaymentInfo'
import AvailabilityAlert from '../Park/AvailabilityAlert'
import BookingInformationEditForm from '../components/BookingInformationEditForm'
import BreadCrumb from '../components/BreadCrumb'
import Footer from '../Footer'
import HeaderComponent from '../Header'
import HomePage from '../HomePage'
import Login from '../Authentication/Connection/components/Login'
import LoginLink from '../Authentication/Connection/components/LoginLink'
import PasswordRecoveryTunnel from '../Authentication/Connection/components/PasswordRecoveryTunnel'
import Place from '../Place'
import PopupRouterWithContext from '../Authentication/components/PopupRouter'
import PurchaseTunnel from '../Purchase/PurchaseTunnel'
import RegistrationAccountStep from '../Authentication/Registration/components/RegistrationAccountStep'
import RegistrationBillingInfoStep from '../Authentication/Registration/components/RegistrationBillingInfoStep'
import RegistrationCarouselWithContext from '../Authentication/Registration/components/RegistrationCarousel'
import ResetPassword from '../Authentication/Connection/components/ResetPassword'
import SearchPage from '../SearchPage'
import SharedReduxStore from '../../../stores/SharedReduxStore'
import VehicleCard from '../components/VehicleInformation/VehicleCard'
import { EditChanges } from '../Purchase/EditDates/EditChanges'
import { Info3dSecureDialog } from 'Components/Info3dSecureDialog'
import { LegacySubscriptionNoticeDialog } from 'Components/LegacySubscriptionNoticeDialog'
import { OnPurchase3dSecureErrorDialog } from 'Components/OnPurchase3dSecureErrorDialog'
import { OnRegistration3dSecureErrorDialog } from 'Components/OnRegistration3dSecureErrorDialog'
import { Park } from '../Park'
import { PerformanceReportSentDialog } from 'Components/PerformanceReportSentDialog'
import { TooltipComponent } from 'Components/TooltipComponent'
import { withI18n } from 'Components/OpI18n'
import { withReduxStore } from 'Components/WithReduxStore'
import ProfilePaymentMethodsList from 'Components/ProfilePaymentMethodsList'
import EditPaymentMethodPage from 'Components/EditPaymentMethodPage'

// Fallback to prefixed versions of requestAnimationFrame
// https://developer.mozilla.org/fr/docs/Web/API/Window/requestAnimationFrame
// https://caniuse.com/#search=requestAnimationFrame
if (!window.requestAnimationFrame) {
  window.requestAnimationFrame = (() => {
    return (
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60)
      }
    )
  })()
}

smoothscroll.polyfill() // requires window.requestAnimationFrame

if (window.Cookies) {
  window.Cookies.set('browser_timezone', moment.tz.guess())
}

ReactOnRails.registerStore({
  SharedReduxStore
})

const withI18nAndReduxStore = compose(
  withI18n,
  withReduxStore
)

const decorate = (Decorated, props, railsContext = {}) => withI18nAndReduxStore(Decorated)({ railsContext, ...props })

ReactOnRails.register({
  AccountCreditCardPaymentInfoWithContext: (props, railsContext) => decorate(AccountCreditCardPaymentInfo, props, railsContext),
  LoginLinkWithContext: (props, railsContext) => decorate(LoginLink, props, railsContext),
  LoginWithContext: (props, railsContext) => decorate(Login, props, railsContext),
  ResetPasswordWithContext: (props, railsContext) => decorate(ResetPassword, props, railsContext),
  PasswordRecoveryTunnelWithContext: (props, railsContext) => decorate(PasswordRecoveryTunnel, props, railsContext),
  RegistrationAccountStepWithContext: (props, railsContext) => decorate(RegistrationAccountStep, props, railsContext),
  RegistrationBillingInfoStepWithContext: (props, railsContext) => decorate(RegistrationBillingInfoStep, props, railsContext),
  RegistrationCarouselWithContext,
  SearchPageWithContext: (props, railsContext) => decorate(SearchPage, props, railsContext),
  PopupRouterWithContext,
  HeaderWithContext: (props, railsContext) => decorate(HeaderComponent, props, railsContext),
  HomePageWithContext: (props, railsContext) => decorate(HomePage, props, railsContext),
  BreadCrumbWithContext: (props, railsContext) => decorate(BreadCrumb, props, railsContext),
  ParkWithContext: (props, railsContext) => decorate(Park, props, railsContext),
  PlaceWithContext: (props, railsContext) => decorate(Place, props, railsContext),
  PurchaseTunnelWithContext: (props, railsContext) => decorate(PurchaseTunnel, props, railsContext),
  FooterWithContext: (props, railsContext) => decorate(Footer, props, railsContext),
  Info3dSecureDialog: (props, railsContext) => decorate(Info3dSecureDialog, props, railsContext),
  OnRegistration3dSecureErrorDialog: (props, railsContext) => decorate(OnRegistration3dSecureErrorDialog, props, railsContext),
  OnPurchase3dSecureErrorDialog: (props, railsContext) => decorate(OnPurchase3dSecureErrorDialog, props, railsContext),
  LegacySubscriptionNoticeDialog: (props, railsContext) => decorate(LegacySubscriptionNoticeDialog, props, railsContext),
  PerformanceReportSentDialog: (props, railsContext) => decorate(PerformanceReportSentDialog, props, railsContext),
  TooltipComponent: (props, railsContext) => decorate(TooltipComponent, props, railsContext),
  AvailabilityAlertWithContext: (props, railsContext) => <AvailabilityAlert {...{ ...props, railsContext }} />,
  VehicleCard: (props, railsContext) => decorate(VehicleCard, props, railsContext),
  BookingInformationEditForm: (props, railsContext) => decorate(BookingInformationEditForm, props, railsContext),
  EditChanges: (props, railsContext) => decorate(EditChanges, props, railsContext),
  ProfilePaymentMethodsListWithContext: (props, railsContext) => decorate(ProfilePaymentMethodsList, props, railsContext),
  EditPaymentMethodPageWithContext: (props, railsContext) => decorate(EditPaymentMethodPage, props, railsContext)
})
