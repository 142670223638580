const dayjs = require('dayjs')

const MONEXT_REGEX = /^\d{6}.*\d{2}$/
const STRIPE_REGEX = /\d{4}$/

export function cardNumberView (number) {
  if (MONEXT_REGEX.test(number)) return `${number.substring(0, 6)}****`
  if (STRIPE_REGEX.test(number)) return `****${number.slice(-4)}`
  return number
}

export const formatDate = (expirationDate, format) => dayjs(expirationDate).format(format)
