import InjectedStripeForm from './StripeForm'
import Loader from 'Components/Loader'
import PropTypes from 'prop-types'
import React, { Component, createRef } from 'react'
import classNames from 'classnames'
import { Elements } from '@stripe/react-stripe-js'
import { FormattedMessage, injectIntl } from 'react-intl'
import MonextForm from 'Components/Form/MonextForm'
import { MonextRadioButton } from 'Components/Form/MonextRadioButton'
import { StripeRadioButton } from 'Components/Form/StripeRadioButton'
import { connect } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { Info3dSecureDialog } from 'Components/Info3dSecureDialog'
import { OnPurchase3dSecureErrorDialog } from 'Components/OnPurchase3dSecureErrorDialog'
import { OnRegistration3dSecureErrorDialog } from 'Components/OnRegistration3dSecureErrorDialog'
import {
  createPendingPaymentMethod,
  fetchMonextWidgetStateChanges,
  transitionPaymentMethodToError
} from 'Helpers/paymentMethodsHelper'

class PaymentInfoPanel extends Component {
  static propTypes = {
    stripePublicKey: PropTypes.string,
    handleStripeChange: PropTypes.func,
    handleStripeError: PropTypes.func,
    hideTitle: PropTypes.bool,
    turnoffWarning: PropTypes.func,
    onCancel: PropTypes.func,
    green: PropTypes.bool,
    externalErrorMessage: PropTypes.string,
    clearExternalErrorMessage: PropTypes.func,
    callingPage: PropTypes.oneOf(['registration', 'booking', 'purchase', 'profile']),
    monextPaymentMethodAllowed: PropTypes.bool.isRequired,
    onMonextError: PropTypes.func,
    initialSelection: PropTypes.string,
    errorMessageId: PropTypes.string,
    onMonextWidgetTokenSet: PropTypes.func,
    clearErrors: PropTypes.func,
    hideMonextForm: PropTypes.bool,
    previousBillingIdentityId: PropTypes.number
  }

  static defaultProps = {
    hideMonextForm: false
  }

  state = {
    acceptTerms: false,
    professionalUse: false,
    showStripeLoader: true,
    gatewayError: null,
    stripe: null,
    setupIntentSecret: null,
    threedsError: false,
    monextWidgetToken: null,
    showMonextLoader: false,
    monextConnectionError: false,
    stripeSelected: (this.props.initialSelection !== 'Monext'),
    monextErrorMessageId: this.props.errorMessageId,
    isNotationContentVisible: true
  }

  getStripeAppearance = () => {
    // https://stripe.com/docs/elements/appearance-api?platform=web
    return this.isCallingFromProfilePage() ? {
      theme: 'stripe',
      variables: {
        colorText: '#32325d',
        colorDanger: '#fa755a',
        fontFamily: 'Inter, Helvetica Neue, Helvetica, sans-serif',
        fontSmooth: 'always',
        fontSize: '16px',
        colorTextPlaceholder: '#aab7c4',
        colorIconCardError: '#fa755a'
      }
    } : {
      theme: 'stripe',
      variables: {
        colorText: '#32325d',
        colorDanger: '#FF570C',
        fontFamily: 'Inter, Helvetica Neue, Helvetica, sans-serif',
        fontSmooth: 'always',
        fontSize: '14px',
        colorTextPlaceholder: '#757575',
        colorIconCardError: '#FF570C'
      }
    }
  }
  stripeRadioButtonRef = createRef()
  monextRadioButtonRef = createRef()

  fetchClientSecret = async () => {
    const setupIntentResponse = await fetch(`/payment/setup-intents`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    if (setupIntentResponse.ok) {
      const setupIntentJSON = await setupIntentResponse.json()
      return setupIntentJSON.data.attributes.client_secret
    } else {
      throw new Error(`Error while connecting to server (HTTP status ${setupIntentResponse.status})`)
    }
  }

  componentDidMount = async () => {
    const stripe = loadStripe(this.props.stripePublicKey, { apiVersion: '2022-11-15' })
    const clientSecret = await this.fetchClientSecret()
    this.setState({ stripe, setupIntentSecret: clientSecret })
    if (this.props.initialSelection === 'Monext') await this.onMonextSelected()
  }

  locationBeforeSummary = () => {
    // it is a bit ugly, maybe we should switch to the props instead of analyzing window.location
    if (typeof window === 'undefined') return true

    return window.location.pathname.endsWith('member/payment-informations')
  }

  show3dsInfo = (event) => { event.preventDefault(); $('#info-3d-secure').modal('show') }

  isCallingFromProfilePage = () => this.props.callingPage === 'profile'

  renderLoader = (overlay = true) => {
    if (this.isCallingFromProfilePage()) return <Loader overlay={overlay} blue />

    return <Loader overlay={overlay} green />
  }

  onStripeSelected = async () => {
    if (this.props.hideMonextForm) return

    this.setState({ stripeSelected: true, monextConnectionError: false, monextErrorMessageId: null, monextWidgetToken: null })
    if (this.props.initialSelection !== 'Stripe') this.props.clearErrors()
    if (this.state.monextWidgetToken) transitionPaymentMethodToError(this.state.monextWidgetToken)
    this.props.onMonextWidgetTokenSet(null)
    this.stripeRadioButtonRef.current.checked = true
  }

  createPendingMonextBillingIdentity = async (token) => {
    try {
      const [pendingCreatedSuccessfully, jsonResponse] = await createPendingPaymentMethod(token)

      if (pendingCreatedSuccessfully) return
      const errorMesageId = jsonResponse.errors[0].code
      this.setState({ monextErrorMessageId: errorMesageId })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  onMonextSelected = async () => {
    if (!this.props.monextPaymentMethodAllowed) return

    this.monextRadioButtonRef.current.checked = true
    this.setState((previousState) => {
      return { stripeSelected: false, showMonextLoader: !previousState.monextWidgetToken, monextConnectionError: false }
    })
    if (this.props.initialSelection !== 'Monext') {
      this.props.clearErrors()
      this.setState({ monextErrorMessageId: null })
    }
    if (this.state.monextWidgetToken) return

    const stateChanges = await fetchMonextWidgetStateChanges(this.props.localePrefix)
    this.setState({ ...stateChanges, showMonextLoader: false })
    if (!stateChanges.hasOwnProperty('monextWidgetToken')) return

    this.props.onMonextWidgetTokenSet(stateChanges.monextWidgetToken)
    await this.createPendingMonextBillingIdentity(stateChanges.monextWidgetToken)
  }

  render () {
    const { stripe, setupIntentSecret } = this.state
    const appearance = this.getStripeAppearance()
    const { currentLocale, handleStripeChange, hideMonextForm } = this.props

    const parentClassName = classNames('payment-info-panel', {
      'payment-info-panel--profile-page': this.isCallingFromProfilePage()
    })

    const stripeRadioButtonTheme = this.isCallingFromProfilePage() ? 'blue' : 'green'

    return (
      <div className={parentClassName}>
        <OnRegistration3dSecureErrorDialog />
        <OnPurchase3dSecureErrorDialog />
        <Info3dSecureDialog />
        {!this.props.hideTitle &&
          <h1>
            <FormattedMessage id='authentication.registration.payment_info.title' />
          </h1>
        }

        <section
          className={classNames('payment-info-panel__section', { 'payment-info-panel__section--selected': this.state.stripeSelected })}
          onClick={this.onStripeSelected}
        >
          <StripeRadioButton selected={this.state.stripeSelected} radioButtonRef={this.stripeRadioButtonRef} theme={stripeRadioButtonTheme} />

          <div className={classNames('payment-info-panel__stripe-section-content', { hidden: !this.state.stripeSelected })}>
            {!this.state.threedsError && this.state.isNotationContentVisible &&
              <div className='payment-info-panel__notation-content'>
                <div className='payment-info-panel__notation-header'>
                  <FormattedMessage id='authentication.registration.payment_info.3ds.charge_warning.before_link' />
                  <a href={'#'} onClick={this.show3dsInfo}>
                    <FormattedMessage id='authentication.registration.payment_info.3ds.charge_warning.link_title' />
                  </a>
                  <FormattedMessage id='authentication.registration.payment_info.3ds.charge_warning.after_link' />
                </div>
                {!this.isCallingFromProfilePage() && (
                  <div className='payment-info-panel__notation-footer'>
                    <FormattedMessage id='authentication.registration.payment_info.not_charged_now' />
                  </div>
                )}
              </div>}

            <div className={`payment-info-panel__form ${this.state.showStripeLoader ? 'payment-info-panel__form--loading' : ''}`}>
              {this.state.showStripeLoader && this.renderLoader()}
              {stripe && setupIntentSecret &&
                <Elements
                  stripe={stripe}
                  options={{ clientSecret: setupIntentSecret, locale: currentLocale, appearance }}>
                  <InjectedStripeForm
                    {...this.props}
                    stripe={stripe}
                    previousBillingIdentityId={this.props.previousBillingIdentityId}
                    handleElementReady={() => this.setState({ showStripeLoader: false })}
                    turnoffWarning={this.props.turnoffWarning}
                    threedsError={this.state.threedsError}
                    showNotationContent={(value) => this.setState({ isNotationContentVisible: value })}
                    threedsErrorSetter={(value) => {
                      this.setState({ threedsError: value })
                      if (this.props.handleStripeError) this.props.handleStripeError()
                    }}
                    handleStripeChange={handleStripeChange}
                    tabletPortraitExclusiveStyles={this.locationBeforeSummary()}
                    externalErrorMessageId={this.state.errorMessageId}
                  />
                </Elements>
              }
            </div>
          </div>
        </section>

        {!hideMonextForm && (
          <section
            className={classNames('payment-info-panel__section',
              {
                'payment-info-panel__section--selected': !this.state.stripeSelected,
                'payment-info-panel__section--disabled': !this.props.monextPaymentMethodAllowed
              })}
            onClick={this.onMonextSelected}
          >
            <MonextRadioButton
              selected={!this.state.stripeSelected}
              disabled={!this.props.monextPaymentMethodAllowed}
              radioButtonRef={this.monextRadioButtonRef}
            />

            <div className={classNames('payment-info-panel__monext-section-content', { hidden: this.state.stripeSelected })}>
              {!this.state.monextConnectionError && !this.isCallingFromProfilePage() && !this.state.monextErrorMessageId &&
                <div className='payment-info-panel__notation-content'>
                  <FormattedMessage id='authentication.registration.payment_info.not_charged_now' />
                </div>
              }
              {this.state.showMonextLoader &&
                <div className='purchase-summary__block--flex purchase-summary__block--bigger-svg purchase-summary__block--centered'>
                  {this.renderLoader(false)}
                </div>
              }
              {this.state.monextWidgetToken !== null &&
                <MonextForm
                  monextWidgetToken={this.state.monextWidgetToken}
                  localePrefix={this.props.localePrefix}
                  intl={this.props.intl}
                  errorMessageId={this.state.monextErrorMessageId}
                  onError={this.props.onMonextError}
                  renderLoader={this.renderLoader}
                />
              }
              {this.state.monextConnectionError &&
                <div className='payment-info-panel__error-message payment-info-panel__error-message--orange'>
                  <FormattedMessage id='purchase.purchase_summary.fetch_monext_token_timed_out' />
                </div>
              }
            </div>
          </section>)
        }
      </div>
    )
  }
}

const mapStateToProps = ({
  railsContext: {
    currentSite: { iso: country }, localePrefix, location, currentLocale
  },
  resources: { isDefaultHost }
}) => ({ country, localePrefix, location, isDefaultHost, currentLocale })

export default injectIntl(connect(mapStateToProps)(PaymentInfoPanel))
