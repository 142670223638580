import {
  defaultDeserializer,
  idDeserializer,
  sendRequestToApi,
  sendRequestToApiRaw,
  transformKeysToCamelCase,
  singleEntryAttributes
} from './requestsToBackend'

export async function createPendingStripeIdentity (previousBillingIdentityId = null, onError = () => {}) {
  const pendingBillingIdentity = await sendRequestToApi(
    `${process.env.PAYMENT_API_HOST}/payment-methods`,
    {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'billing-identity',
          attributes: {
            type: 'stripe-identity',
            'previous-billing-identity-id': previousBillingIdentityId
          },
          relationships: {
            user: {
              data: {
                uuid: window._oneparkDatas.user_uuid,
                type: 'user'
              }
            }
          }
        }
      })
    },
    billingIdentitySerializer,
    onError
  )
  return pendingBillingIdentity
}

const billingIdentitySerializer = ({ data: { attributes } }) => ({
  ...transformKeysToCamelCase(attributes), expiresAt: attributes['expiration-date']
})

export async function convertBillingIdentityToErrorState (billingIdentityId) {
  await sendRequestToApi(
    `${process.env.PAYMENT_API_HOST}/payment-methods/${billingIdentityId}/transition-to-error`,
    {
      method: 'PATCH',
      body: JSON.stringify({
        data: {
          type: 'billing-identity',
          attributes: {},
          relationships: {
            user: {
              data: {
                uuid: window._oneparkDatas.user_uuid,
                type: 'user'
              }
            }
          }
        }
      })
    },
    () => {},
    (response) => handlePatchPaymentMethodErrors(response, billingIdentityId)
  )
}

const BILLING_IDENTITY_CANNOT_BE_ERROR_AS_IT_IS_FINISHED = 'billing_identity_cannot_be_error_as_it_is_finished'
const BILLING_IDENTITY_ALREADY_IN_ERROR = 'billing_identity_is_already_in_error_state'

const PATCH_PAYMENT_METHOD_HANDLEABLE_ERROR_CODES = [
  BILLING_IDENTITY_CANNOT_BE_ERROR_AS_IT_IS_FINISHED,
  BILLING_IDENTITY_ALREADY_IN_ERROR
]

const handlePatchPaymentMethodErrors = (response, billingIdentityId) => {
  if (response && response.errors) {
    response.errors.forEach(({ code }) => {
      if (PATCH_PAYMENT_METHOD_HANDLEABLE_ERROR_CODES.includes(code)) {
        captureExceptionToSentry(code, billingIdentityId)
      }
    })
  }

  captureExceptionToSentry(
    'Error occurred during converting billing identity to error state',
    billingIdentityId
  )
}

export async function fetchBillingIdentityById (billingIdentityId, signal = null) {
  try {
    const userUuid = window._oneparkDatas.user_uuid
    const fetchOptions = signal ? { signal } : {}
    const billingIdentity = await sendRequestToApi(
      `${process.env.PAYMENT_API_HOST}/payment-methods/${billingIdentityId}?user_uuid=${userUuid}`,
      fetchOptions, billingIdentitySerializer
    )
    return billingIdentity
  } catch {}
}

export async function deleteBillingIdentityById (billingIdentityId, onError = () => {}) {
  const userUuid = window._oneparkDatas.user_uuid
  await sendRequestToApi(
    `${process.env.PAYMENT_API_HOST}/payment-methods/${billingIdentityId}?user_uuid=${userUuid}`,
    { method: 'DELETE' },
    () => {},
    onError
  )
}

function captureExceptionToSentry (message, billingIdentityId = null) {
  Sentry.withScope((scope) => {
    if (billingIdentityId) scope.setTag('billing_identity_id', billingIdentityId)
    Sentry.captureException(new Error(message))
  })
}

export async function createPendingPaymentMethod (monextWidgetToken) {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), 10000)
  try {
    const rawResponse = await sendRequestToApiRaw(
      `${process.env.PAYMENT_API_HOST}/payment-methods`,
      {
        method: 'POST',
        signal: controller.signal,
        body: JSON.stringify({
          data: {
            attributes: {
              type: 'monext-identity',
              authorization: monextWidgetToken
            },
            relationships: { user: { data: { uuid: window._oneparkDatas.user_uuid } } }
          }
        })
      }
    )
    clearTimeout(timeoutId)
    const jsonBody = await rawResponse.json()
    return [rawResponse.status === 201, jsonBody]
  } catch (error) {
    return [false, error]
  }
}

export async function fetchPaymentMethodState ({ monextWidgetToken, connectionTimeout = 2000 }) {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), connectionTimeout)
  const paymentApiUrl = `${process.env.PAYMENT_API_HOST}/payment-methods/${monextWidgetToken}?` +
    `user_uuid=${window._oneparkDatas.user_uuid}&find_by_token=true`

  try {
    const fetchedValue = await sendRequestToApi(paymentApiUrl, { signal: controller.signal }, singleEntryAttributes)
    clearTimeout(timeoutId)
    const stateChange = fetchedValue.hasOwnProperty('state') ? { paymentMethodState: fetchedValue.state } : {}
    const errorMessageChange = fetchedValue.hasOwnProperty('error-code') ? { paymentMethodErrorId: fetchedValue['error-code'] } : {}
    return { ...stateChange, ...errorMessageChange }
  } catch (error) {
    Sentry.captureException(error)
    return {}
  }
}

export const DEFAULT_BILLING_IDENTITY_FIELDS = [
  'id', 'expiration-date', 'brand', 'state',
  'error-code', 'error-title', 'card-number',
  'is-default', 'allows-threeds', 'type'
]

export async function fetchPaymentMethods ({ connectionTimeout = 60000, onError = () => {}, fields = DEFAULT_BILLING_IDENTITY_FIELDS }) {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), connectionTimeout)
  const paymentApiUrl = `${process.env.PAYMENT_API_HOST}/payment-methods?user_uuid=${window._oneparkDatas.user_uuid}&fields[billing-identity]=${fields}`

  try {
    const fetchedValues = await sendRequestToApi(paymentApiUrl, { signal: controller.signal }, defaultDeserializer, onError)
    clearTimeout(timeoutId)
    return fetchedValues
  } catch (error) {
    onError()
    Sentry.captureException(error)
    return []
  }
}

export async function transitionPaymentMethodToError (monextWidgetToken) {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), 10000)
  try {
    const rawResponse = await sendRequestToApiRaw(
      `${process.env.PAYMENT_API_HOST}/payment-methods/${monextWidgetToken}/transition-to-error?find_by_token=true`,
      {
        method: 'PATCH',
        signal: controller.signal,
        body: JSON.stringify({
          data: {
            relationships: { user: { data: { uuid: window._oneparkDatas.user_uuid } } }
          }
        })
      }
    )
    clearTimeout(timeoutId)
    const jsonBody = await rawResponse.json()
    return [rawResponse.status === 200, jsonBody]
  } catch (error) {
    Sentry.captureException(error)
    return [false, error]
  }
}

export async function fetchMonextWidgetStateChanges (propsLocalePrefix) {
  // .co/ch-fr -> fr, .co/de -> de, .co -> '', .fr -> ''
  const localePrefix = propsLocalePrefix.replace(/\/*/g, '').split('-').slice(-1)[0]
  const localeByDomain = window.location.hostname.split('.').slice(-1)[0] === 'fr' ? 'fr' : 'en'
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), 30000)
  try {
    const token = await sendRequestToApi(
      `${process.env.PAYMENT_API_HOST}/monext/sessions`,
      {
        method: 'POST',
        signal: controller.signal,
        body: JSON.stringify({
          locale: localePrefix === '' ? localeByDomain : localePrefix,
          return_url_on_success: window.location.href,
          return_url_on_failure: window.location.href,
          webhook: `${process.env.PAYMENT_API_HOST}/monext-webhook`
        })
      },
      idDeserializer
    )
    clearTimeout(timeoutId)
    return { monextWidgetToken: token }
  } catch (error) {
    return { monextConnectionError: true }
  }
}
