import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  arrow,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId, FloatingArrow
} from '@floating-ui/react'
import iconInfo from 'Assets/images/svg-on-react/icon-info.svg'
import SvgImage from 'Components/SvgImage'
import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

export function FloatingPopover (props) {
  const [isOpen, setIsOpen] = useState(props.paymentMethodId === props.openedToolTipId)

  const arrowRef = useRef(null)

  const onOpenChange = (newIsOpen) => {
    setIsOpen(newIsOpen)
    if (props.onToolTipOpened) props.onToolTipOpened(newIsOpen ? props.paymentMethodId : null)
  }

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange,
    middleware: [
      offset(10), // Space between reference and floating element
      flip(), // Flip the tooltip if there's not enough space
      shift({ padding: 25 }), // Shift the tooltip to stay within bounds
      arrow({ element: arrowRef })
    ],
    whileElementsMounted: autoUpdate
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role
  ])

  const headingId = useId()

  const handleClose = () => onOpenChange(false)

  return (
    <div className='floating-popover'>
      <div className={props.className || 'floating-popover__anchor'} ref={refs.setReference} {...getReferenceProps()}>
        <SvgImage svg={props.tooltipIcon || iconInfo} className='tooltip-op__icon' />
      </div>
      {isOpen && (
        <FloatingFocusManager context={context} closeOnFocusOut disabled returnFocus={false} onDismiss={handleClose}>
          <div
            className='floating-popover__content'
            onClick={handleClose}
            ref={refs.setFloating}
            style={floatingStyles}
            aria-labelledby={headingId}
            {...getFloatingProps()}
          >
            <FloatingArrow ref={arrowRef} context={context} stroke='#616161' strokeWidth={0.5} />
            <div className='floating-popover__message'>
              <FormattedMessage id={props.messageId} />
            </div>
            <div className='floating-popover__close-button' onClick={handleClose}>&times;</div>
          </div>
        </FloatingFocusManager>
      )}
    </div>
  )
}
