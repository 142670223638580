import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  defaultPaymentMethods,
  paymentMethodsByCountry,
  paymentIconByName
} from 'Constants/payment'
import SvgImage from '../SvgImage'
import iconMoney from '../../../../../assets/images/icon-money-piles.svg'
import iconNoCommitment from 'Assets/images/svg-on-react/icon-no-commitment.svg'

const ReassurancePanel = ({ country, isSubscription, bookingBanner }) => {
  const availablesPaymentMethod = paymentMethodsByCountry[country] || defaultPaymentMethods

  return (
    <div className={classNames('reassurance-panel panel panel-default',
      { 'reassurance-panel--without-margin-top': !bookingBanner })}>
      <div className={classNames('panel-body', {
        'panel-body--no-side-margins-on-mobile': bookingBanner,
        'panel-body--with-horizontal-line': !bookingBanner
      })}>
        <ul>
          {isSubscription &&
            <li className='reassurance-panel__item'>
              <SvgImage svg={iconNoCommitment} />
              <FormattedMessage id='pages.park.reassurance.commitment' />
            </li>
          }
          <li className='reassurance-panel__item'>
            <SvgImage svg={iconMoney} />
            <FormattedMessage id='pages.park.reassurance.free_cancel' />
          </li>
          <li className='reassurance-panel__item reassurance-panel__item--silver-box'>
            <div>
              {availablesPaymentMethod.map(name =>
                <div className='reassurance-panel__cards-icon'>
                  <img src={paymentIconByName[name].src} alt={paymentIconByName[name].alt} />
                </div>
              )}
            </div>
            <FormattedMessage id='pages.park.reassurance.secured_payment' />
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ReassurancePanel
