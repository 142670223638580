import classNames from 'classnames'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AddressSearchInput from '../../components/Form/AddressSearchInput'
import FormInputDatePicker from '../../components/Form/FormInputDatePicker'
import FormInputTimePicker from '../../components/Form/FormInputTimePicker'
import { SearchSubmitButton } from './SearchSubmitButton'
import {
  getTimePickerOptions,
  getCeilQuarterOf,
  getDateAtCurrentTime,
  getEndDateAfterBeginDateChange,
  getEndDateAfterEndDateChange,
  clearSearchFromCookie,
  isEnterKeyPressed
} from '../../helpers/search'
import { datalayerPushGTM } from '../../helpers/application'
import { ADDRESS_ELEMENT_IDS } from '../../constants/application'
import * as SearchActions from '../../../../actions/SearchActions'
import { isMobile } from '../../helpers/browser'
import { hideBackdropOverlay } from '../../helpers/backdropOverlay'

class JumboSearchPackage extends Component {
  state = {
    // Address field
    addressError: false,
    addressFocus: false,
    // Begin date-time field
    beginDateTimeFocus: false,
    beginDateTimeError: false,
    // End date-time field
    endDateTimeFocus: false,
    endDateTimeError: false
  }

  componentDidMount = () => {
    const lastVisit = localStorage.getItem("lastVisit")
    const now = moment()
    const isFirstVisit = !lastVisit
    const isPastThirtyDays = lastVisit && now.isAfter(moment(lastVisit).add(30, 'days'))
    const showOverlay = isFirstVisit || isPastThirtyDays
    const showCalendar = !!(showOverlay && !isMobile() && this.getParams().placeId)

    if (showCalendar) {
      this.focusBeginDatePicker()
    }
  }

  handleAddressChange = ({ address, addressCompleted, place }) => {
    this.setState({ addressError: false })

    if (addressCompleted && !this.getParams().beginDate && this.props.searchMode) {
      this.focusBeginDatePicker()
    } else if (!address) {
      clearSearchFromCookie()
      this.setState({ addressFocus: true, beginDateTimeFocus: false })
    }

    datalayerPushGTM('user-interaction', 'Search', 'change-query-address')

    return this.updateParams({
      address,
      ...(place && place.geometry ? { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() } : {})
    })
  }

  handleBeginDateChange = (beginDate) => {
    datalayerPushGTM('user-interaction', 'Search', 'change-begin-date')
    const endDate = getEndDateAfterBeginDateChange(this.getParams().endDate, beginDate)
    this.updateParams({ beginDate, endDate })
  }

  handleEndDateChange = (endDate) => {
    datalayerPushGTM('user-interaction', 'Search', 'change-end-date')
    endDate = getEndDateAfterEndDateChange(this.getParams().beginDate, this.getParams().endDate, endDate)
    this.updateParams({ endDate })
  }

  getParams = () => this.props.searchMode ? this.props.nextParams : this.props.params

  updateParams = (params) =>
    (this.props.searchMode ? this.props.actions.updateNextSearchParams : this.props.actions.updateSearchParams)(params)

  focusBeginDatePicker = () => {
    this.loadDefaultDateTimes()
    this.dayPickerBeginInput.input.focus()
    this.focusBeginDateTimeGroup()
  }

  focusBeginTimePicker () {
    this.setState({ beginDateTimeFocus: true })
    setTimeout(() => this.timePickerBeginInput.focus(), 0)
  }

  focusEndDatePicker () {
    this.dayPickerEndInput.input.focus()
    this.focusEndDateTimeGroup()
  }

  focusEndTimePicker () {
    this.setState({ endDateTimeFocus: true })
    setTimeout(() => this.timePickerEndInput.focus(), 0)
  }

  loadDefaultDateTimes () {
    const { beginDate, endDate } = this.getParams()
    const newBeginDate = beginDate || getCeilQuarterOf(moment())
    const newEndDate = newBeginDate.clone().add(1, 'hour')
    if (!beginDate) {
      this.handleBeginDateChange(newBeginDate)
    }
    if (!endDate || endDate.isBefore(newBeginDate)) {
      this.handleEndDateChange(newEndDate)
    }
  }

  focusBeginDateTimeGroup () {
    this.setState({ addressFocus: false, beginDateTimeFocus: true, endDateTimeFocus: false, beginDateTimeError: false })
  }

  focusEndDateTimeGroup () {
    this.setState({ addressFocus: false, beginDateTimeFocus: false, endDateTimeFocus: true, endDateTimeError: false })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.submitSearch()
  }

  focusHandler = (hasFocus) => {
    this.setState({ addressFocus: hasFocus })
  }

  handleKeyDown = async e => {
    const { address, beginDate, endDate } = this.getParams()
    const { addressFocus, beginDateTimeFocus, endDateTimeFocus } = this.state
    if (isEnterKeyPressed(e)) {
      e.preventDefault()

      if (beginDate && beginDateTimeFocus) {
        this.dayPickerBeginInput.hideDayPicker()
        this.setState({ beginDateTimeFocus: false })
      }

      if (endDate && endDateTimeFocus) {
        this.dayPickerEndInput.hideDayPicker()
        this.setState({ endDateTimeFocus: false })
      }

      if (addressFocus) {
        this.setState({ addressFocus: false })
      }

      if (beginDate && endDate && address) {
        if (ADDRESS_ELEMENT_IDS.includes(e.target.id) && e.target.value !== address) {
          await this.handleAddressChange({ address: e.target.value, addressCompleted: true })
        }

        this.handleSubmit(e)
      }
    }
  }

  validate () {
    const { address, beginDate, endDate } = this.getParams()
    const { searchMode } = this.props
    const addressError = !address && searchMode
    const beginDateTimeError = !beginDate
    const endDateTimeError = !endDate

    if (addressError) {
      datalayerPushGTM('form-tracking', 'Forms', 'Packages', 'Please enter an address')
    }
    this.setState({ addressError, beginDateTimeError, endDateTimeError })

    return !addressError && !beginDateTimeError && !endDateTimeError
  }

  handleDateTimeBlurring (picker) {
    this.setState({ [`${picker}DateTimeFocus`]: false })
  }

  setCorrectTime = (changedDate, currentDate) => {
    let newDate = changedDate

    if (currentDate) {
      newDate = changedDate.clone().set({ hour: currentDate.hour(), minute: currentDate.minute() })
    }

    if (newDate.isBefore(moment())) {
      newDate = getDateAtCurrentTime(changedDate)
    }

    return newDate
  }

  submitSearch () {
    const isFormValid = this.validate() // This also highlights fields on error

    if (isFormValid && this.props.onSubmit) {
      hideBackdropOverlay('place-backdrop')
      this.props.onSubmit()
    }
  }

  render () {
    const { address, beginDate } = this.getParams()
    let { endDate } = this.getParams()
    const { idSuffix, railsContext, loading, searchMode, submitButtonClass = {}, showSearchIcon = true } = this.props
    const { hoursFormat } = railsContext
    const {
      addressError, beginDateTimeError, endDateTimeError,
      beginDateTimeFocus, endDateTimeFocus, addressFocus } = this.state

    const beginDateTimeState = classNames('inputDateTimePicker', 'u-clearfix', {
      'inputDateTimePicker--has-value': !!beginDate,
      'inputDateTimePicker--focus': beginDateTimeFocus,
      'is-invalid': !beginDateTimeFocus && beginDateTimeError
    })
    const endDateTimeState = classNames('inputDateTimePicker', 'u-clearfix', {
      'inputDateTimePicker--has-value': !!endDate,
      'inputDateTimePicker--focus': endDateTimeFocus,
      'is-invalid': !endDateTimeFocus && endDateTimeError
    })
    const datetimePickerClass = classNames('form-elem', {
      'col-xs-12 col-sm-4 col-md-3': searchMode,
      'col-xs-12 col-sm-12 col-md-12': !searchMode
    })

    const beginTimePickerDate = (beginDate && beginDate.isAfter(moment(), 'day'))
      ? beginDate.clone().startOf('day')
      : getCeilQuarterOf(moment())

    let endTimePickerDate
    if (endDate && endDate.isAfter(beginDate || moment(), 'day')) {
      endTimePickerDate = endDate.clone().startOf('day')
    } else {
      endTimePickerDate = getCeilQuarterOf(beginDate || moment()).add(1, 'hour')
      const deltaOffset = (beginDate || moment()).utcOffset() - endTimePickerDate.utcOffset()
      if (deltaOffset > 0) {
        endTimePickerDate.add(deltaOffset, 'minutes')
        if (endDate && endDate.isBefore(endTimePickerDate)) {
          endDate = endTimePickerDate.clone()
        }
      }
    }

    return (
      <div className='row row--condensed'>
        <form onSubmit={this.handleSubmit} onKeyDown={this.handleKeyDown} ref={el => { this.form = el }}>
          <div className='op-search__form-inputs'>
            {searchMode &&
              <div className='form-elem col-xs-12 col-sm-12 col-md-6'>
                <AddressSearchInput
                  id={`packageAddressSearch${idSuffix}`}
                  value={address || ''}
                  placeholderKey='pages.homepage.search.address'
                  onChange={this.handleAddressChange}
                  hasError={addressError}
                  hasFocus={addressFocus}
                  railsContext={railsContext}
                  onFocus={this.focusHandler}
                />
              </div>}
            <div id='begin-datetime' className={datetimePickerClass}>
              <div className={beginDateTimeState}>
                <div className='col-xs-7 p-0'>
                  <FormInputDatePicker
                    id={`searchBeginDate${idSuffix}`}
                    value={beginDate}
                    disabledBefore={moment()}
                    placeholderKey='pages.homepage.search.begin_date'
                    onFocus={this.focusBeginDatePicker}
                    openCallback={() => this.focusBeginDateTimeGroup()}
                    setCallback={(date) => {
                      this.handleBeginDateChange(this.setCorrectTime(date, beginDate))
                      if (searchMode) {
                        this.focusBeginTimePicker()
                      }
                    }}
                    inputRef={el => { this.dayPickerBeginInput = el }}
                    onDateBlur={() => this.handleDateTimeBlurring('begin')}
                  />
                </div>
                <div className='col-xs-5 p-0'>
                  <FormInputTimePicker
                    id={`searchBeginTime${idSuffix}`}
                    value={beginDate ? beginDate.toISOString() : ''}
                    placeholderKey='pages.homepage.search.time'
                    options={getTimePickerOptions(beginTimePickerDate, hoursFormat)}
                    openCallback={() => this.focusBeginDateTimeGroup()}
                    setCallback={isoDate => {
                      this.handleBeginDateChange(moment(isoDate))
                      if (searchMode) {
                        this.focusEndDatePicker()
                      }
                    }}
                    inputRef={el => { this.timePickerBeginInput = el }}
                    onTimeBlur={() => this.handleDateTimeBlurring('begin')}
                  />
                </div>
              </div>
            </div>
            <div id='end-datetime' className={datetimePickerClass}>
              <div className={endDateTimeState}>
                <div className='col-xs-7 p-0'>
                  <FormInputDatePicker
                    id={`searchEndDate${idSuffix}`}
                    value={endDate}
                    disabledBefore={beginDate || moment()}
                    placeholderKey='pages.homepage.search.end_date'
                    disableDateBefore={beginDate}
                    openCallback={() => this.focusEndDateTimeGroup()}
                    setCallback={(date) => {
                      this.handleEndDateChange(this.setCorrectTime(date, endDate))
                      if (searchMode) {
                        this.focusEndTimePicker()
                      }
                    }}
                    inputRef={el => { this.dayPickerEndInput = el }}
                    onDateBlur={() => this.handleDateTimeBlurring('end')}
                  />
                </div>
                <div className='col-xs-5 p-0'>
                  <FormInputTimePicker
                    id={`searchEndTime${idSuffix}`}
                    value={endDate ? endDate.toISOString() : ''}
                    placeholderKey='pages.homepage.search.time'
                    options={getTimePickerOptions(endTimePickerDate, hoursFormat)}
                    openCallback={() => this.focusEndDateTimeGroup()}
                    setCallback={isoDate => {
                      this.handleEndDateChange(moment(isoDate))
                      if (searchMode) {
                        $(this.form).find('.op-search__form-cta .btn-primary').focus()
                      }
                      if (!isMobile() && document.getElementsByClassName('place-backdrop')[0]) {
                        this.submitSearch()
                      }
                    }}
                    inputRef={el => { this.timePickerEndInput = el }}
                    onTimeBlur={() => this.handleDateTimeBlurring('end')}
                  />
                </div>
              </div>
            </div>
          </div>
          {searchMode &&
            <SearchSubmitButton showSearchIcon={showSearchIcon} loading={loading} submitButtonClass={submitButtonClass} />}
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({
  bookingData: { state },
  search: { nextParams, params },
  railsContext
}) => ({
  nextParams,
  params,
  parkState: state,
  railsContext
})

function mapDispatchToProps (dispatch) {
  return { actions: bindActionCreators({ ...SearchActions }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(JumboSearchPackage)
