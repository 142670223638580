import classNames from 'classnames'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import iconTrash from '../../../../../assets/images/icon-trash.svg'
import iconPencil from '../../../../../assets/images/icon-pencil-blue.svg'
import { isMobile } from 'Helpers/browser'
import { CardLogo } from './CardLogo'
import { cardNumberView, formatDate } from './helpers'

class ProfilePaymentMethodCard extends Component {
  static propTypes = {
    paymentMethod: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired
  }

  onPaymentMethodEdit = () => {
    const { paymentMethod: { id } } = this.props
    window.location.href = `${this.props.localePrefix}/payment/sources/${id}/edit`
  }

  isExpired = (expirationDate) => {
    const currentDate = new Date()
    const expiration = new Date(expirationDate)

    const isSameMonthAndYear =
      expiration.getFullYear() === currentDate.getFullYear() &&
      expiration.getMonth() === currentDate.getMonth()

    return !isSameMonthAndYear && expiration < currentDate
  }

  onPaymentMethodDelete = () => {
    const { onDelete, paymentMethod } = this.props
    onDelete(paymentMethod)
  }

  render () {
    const { brand, cardNumber, expirationDate, requiresUpdate, type } = this.props.paymentMethod
    const wrapperClassName = classNames('profile-payment-method-card', {
      'profile-payment-method-card--requires-update': requiresUpdate
    })
    const isStripeCard = type === 'stripe-identity'
    const dataContainerClassName = classNames('profile-payment-method-card__data-container', {
      'profile-payment-method-card__data-container--mobile': isMobile()
    })

    const actionsClassName = classNames('profile-payment-method-card__actions', {
      'profile-payment-method-card__actions--mobile': isMobile()
    })

    const expiresAtClassName = classNames(
      'profile-payment-method-card__data-component',
      {
        'profile-payment-method-card__data-component--expired': this.isExpired(expirationDate)
      }
    )

    return (
      <div className={wrapperClassName}>
        <div className={dataContainerClassName}>
          <div className='profile-payment-method-card__data-component profile-payment-method-card__data-component--width40'>
            <CardLogo brand={brand} />
          </div>
          <div className='profile-payment-method-card__data-component profile-payment-method-card__data-component--card-number'>
            {cardNumberView(cardNumber.toString())}
          </div>
          <div className={expiresAtClassName}>
            <FormattedMessage id='purchase.purchase_summary.format_expires_at_short' >
              {format => formatDate(expirationDate, format)}
            </FormattedMessage>
          </div>
        </div>
        <div className={actionsClassName}>
          {isStripeCard && (
            <svg height='18px' width='18px' onClick={this.onPaymentMethodEdit} className='profile-payment-method-card__delete-action'>
              <use href={iconPencil} />
            </svg>
          )}
          <svg height='18px' width='18px' onClick={this.onPaymentMethodDelete} className='profile-payment-method-card__delete-action'>
            <use href={iconTrash} />
          </svg>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  railsContext: { localePrefix }
}) => ({ localePrefix })

export default connect(mapStateToProps)(ProfilePaymentMethodCard)
