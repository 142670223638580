import React from 'react'
import { FormattedMessage } from 'react-intl'
import URLSearchParams from 'url-search-params'
import { connect } from 'react-redux'
import { alignStepperToBox, resizeWindow } from '../helpers/resizeWindow'
import RegistrationBreadcrumb from '../components/RegistrationBreadcrumb'
import RegistrationBillingInfoStepForm from './RegistrationBillingInfoStepForm'
import { emitGa4PageView } from '../../../../../libs/google_analytics_helpers'
import { EDIT_URL_KEY } from 'Constants/application'

class RegistrationBillingInfoStep extends React.Component {
  componentDidMount () {
    resizeWindow()
    alignStepperToBox()
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('origin') === 'booking') {
      emitGa4PageView('billing_info')
    }
  }

  handleCancel = () => {
    const urlParams = new URLSearchParams(this.props.location.split('?')[1])
    const editUrl = sessionStorage.getItem(EDIT_URL_KEY)
    if (editUrl) {
      window.location = editUrl
    } else {
      window.location = `${this.props.localePrefix}/purchases/summary?offer_id=${urlParams.get('offer_id')}`
    }
  }

  componentDidUpdate () {
    resizeWindow()
    alignStepperToBox()
  }

  render () {
    const { location, source, mobilePurchaseTunnel } = this.props
    const insidePurchaseFunnel = source === 'booking'
    const textIntro = insidePurchaseFunnel
      ? 'authentication.registration.billing_info.intro_booking'
      : 'authentication.registration.billing_info.intro'
    const title = mobilePurchaseTunnel && insidePurchaseFunnel
      ? 'authentication.registration.billing_info.title_mobile'
      : 'authentication.registration.billing_info.title'
    const urlParams = new URLSearchParams(location.split('?')[1])
    const editMode = urlParams.get('action') === 'edit'
    const editModeClass = editMode ? 'registration-billing-info--edit-mode' : 'registration-billing-info--nonedit-mode'

    return (
      <div className={`react-component registration-billing-info ${editModeClass}`}>
        {!editMode &&
          <div className='row'>
            <RegistrationBreadcrumb
              previousSteps={[]} activeStepId='billing' futureSteps={this.props.futureSteps}
              location={location} localePrefix={this.props.localePrefix}
              offerId={urlParams.get('offer_id')}
              insideLogoClasses='registration__logo--hidden-on-tablet'
              stepperClasses='stepper--no-padding'
              additionalClasses='registration__breadcrumb-wrapper--centered-on-tablet'
            />
          </div>
        }

        <div className={`row registration__block--right ${editMode ? 'registration__block--borderless-on-tablet' : ''}`}>
          {editMode &&
            <div className='row'>
              <RegistrationBreadcrumb
                previousSteps={[]} activeStepId='billing' futureSteps={this.props.futureSteps}
                location={location} localePrefix={this.props.localePrefix}
                offerId={urlParams.get('offer_id')} handleCancel={this.handleCancel}
              />
            </div>
          }
          <div className='registration-billing-info__intro'>
            <h1>
              <FormattedMessage id={title} />
              {editMode &&
                <button className='header-purchase__edit-close header-purchase__edit-close--hidden-on-desktop'
                  onClick={this.handleCancel} data-automation-id='registration__breadcrumb-close-button'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              }
            </h1>
            {(!mobilePurchaseTunnel || source === 'header') && <p><FormattedMessage id={textIntro} /></p>}
          </div>

          <RegistrationBillingInfoStepForm {...this.props} editMode={editMode} handleCancel={this.handleCancel} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  resources: { mobilePurchaseTunnel, localePrefix },
  railsContext: { location }
}) => ({ mobilePurchaseTunnel, localePrefix, location })

export default connect(mapStateToProps)(RegistrationBillingInfoStep)
