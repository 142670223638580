import React, { Component } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'
import URLSearchParams from 'url-search-params'

import FooterBanner from '../../components/FooterBanner'
import Loader from '../../components/Loader'
import ModalOp from '../../components/ModalOp'
import NoOfferReason, { hasNoOfferReason } from '../../components/NoOfferReason'
import BookingPanel from '../BookingPanel'
import ReassurancePanel from '../../components/BlockSections/ReassurancePanel'
import { datalayerPush, datalayerPushGTM, setCookieValue } from '../../helpers/application'
import { renderLoginModal } from '../../helpers/authentication'
import { PURCHASE_URL_COOKIE } from '../../constants/application'

class BookingBanner extends Component {
  state = {
    modalVisible: this.getParams().get('modal') === 'booking'
  }

  componentDidMount () {
    if (this.state.modalVisible) {
      if ($('#react-booking-modal').is(':visible')) {
        $('#react-booking-modal').modal('show')
      } else {
        // The modal param shouldn't have been there -> cleanup
        this.handleModalClosed()
      }
    }
  }

  getPurchaseUrl () {
    const { beginDate, endDate, bestOffer: { id }, parkState: { type }, localePrefix } = this.props
    let url = `${localePrefix}/purchases/summary?offer_id=${id}&begin_date=${beginDate.toISOString(true)}`
    url += (type === 'subscription') ? '&type=subscription' : `&end_date=${endDate.toISOString(true)}`
    return url
  }

  handleClickCheckout = () => {
    const params = this.getParams()
    const { abtestParkMobile, bestOffer, parkState } = this.props

    if (abtestParkMobile && bestOffer && !hasNoOfferReason(parkState)) {
      window.location.href = this.getPurchaseUrl()
    } else {
      params.delete('modal')
      params.append('modal', 'booking')
      window.history.replaceState({}, '', `${location.pathname}?${params}`)
      datalayerPush({ event: 'page_name', page_name: `modal_booking` })
      datalayerPushGTM('hitVirtualPageView')
      this.setState({ modalVisible: true }, () => $('#react-booking-modal').modal('show'))
    }
  }

  handleModalClosed = () => {
    const params = this.getParams()
    params.delete('modal')
    window.history.replaceState({}, '', `${location.pathname}?${params}`)
    datalayerPush({ event: 'page_name', page_name: window._oneparkDatas.page_name })
    datalayerPushGTM('closeVirtualPageView')
    this.setState({ modalVisible: false })
  }

  handleAvailabilityAlertClick = (e) => {
    e.preventDefault()
    window.location.href = `${window.location.pathname}/availability-alert`
  }

  getParams () {
    const query = typeof window !== 'undefined'
      ? window.location.search
      : this.props.railsContext.location.split('?')[1]
    return new URLSearchParams(query)
  }

  handleBookingClick = async (event) => {
    event.preventDefault()
    const book = new Promise(this.tryBooking)

    await book
    this.setState({ loading: false })
  }

  tryBooking = () => {
    if (this.props.userSignedIn) {
      this.setState({ loading: true })
      window.location.href = this.getPurchaseUrl()
    } else {
      setCookieValue(PURCHASE_URL_COOKIE, this.getPurchaseUrl(), 10)
      renderLoginModal(this.props.railsContext, 'booking')
    }
  }

  render () {
    const { country, park, userSignedIn, subscriptionOffers, isUserOnMobile, type } = this.props
    const { modalVisible } = this.state

    return (
      <div className='booking-banner__root'>

        <FooterBanner className='booking-banner container'>
          {this.renderCTA()}
        </FooterBanner>

        {modalVisible && (
          <ModalOp id='react-booking-modal' mobileOnly autoHideOnResize onModalClosed={this.handleModalClosed}>
            <BookingPanel
              park={park}
              isUserOnMobile={isUserOnMobile}
              userSignedIn={userSignedIn}
              subscriptionOffers={subscriptionOffers}
              parentComponent='booking-banner' />
            <ReassurancePanel country={country} isSubscription={type === 'subscription'} bookingBanner />
          </ModalOp>
        )}
      </div>
    )
  }

  renderCTA = () => {
    const { bestOffer, noOfferReason, parkState, type, park, beginDate, endDate } = this.props
    const unavailable = ['soon_available', 'unavailable', 'invisible'].includes(parkState)
    const { loading } = this.state
    const initialUpdate = !parkState || loading

    if (initialUpdate) {
      return (
        <div className='booking-banner__flex' onClick={this.handleAvailabilityAlertClick}>
          <Loader overlay />
        </div>
      )
    } else if (unavailable) {
      return (
        <div className='booking-banner__flex' onClick={this.handleAvailabilityAlertClick}>
          <NoOfferReason baseClassName='booking-banner__error' state={parkState} reason={noOfferReason} isSubscription={type === 'subscription'} />
          {this.renderGetAlertButton()}
        </div>
      )
    } else if (bestOffer && !hasNoOfferReason(parkState)) {
      return (
        <div className='booking-banner__flex booking-banner__flex--vertical' >
          <div className='booking-banner__container'>
            {type === 'package' && this.renderPackageOfferDates(beginDate, endDate)}
            {type === 'subscription' && this.renderSubscriptionOfferDates(beginDate)}
            {this.renderChangeButton()}
          </div>
          <div className='booking-banner__container'>
            {this.renderBestOffer(bestOffer, park.onSitePrice, type)}
            {this.renderBookButton(type)}
          </div>
        </div>
      )
    }
    return (
      <div onClick={this.handleClickCheckout}>
        {this.renderCheckoutButton()}
      </div>
    )
  }

  renderGetAlertButton = () => (
    <button className='btn btn-primary booking-banner__get-alert-button'>
      <FormattedMessage id='pages.park.booking_panel.cta_alert' />
    </button>
  )

  renderChangeButton = () => (
    <button className='btn btn-primary booking-banner__change-booking-button' onClick={this.handleClickCheckout}>
      <FormattedMessage id='pages.park.booking_banner.cta_change' />
    </button>
  )

  renderBookButton = (type) => (
    <button className='btn btn-primary booking-banner__book-button' onClick={this.handleBookingClick}>
      <FormattedMessage id={`pages.park.booking_banner.cta_book.${type}`} />
    </button>
  )

  renderCheckoutButton = () => (
    <button className='btn btn-primary booking-banner__checkout-button' id='checkoutButton'>
      <FormattedMessage id='pages.park.booking_banner.cta_checkout' />
    </button>
  )

  renderPackageOfferDates = (beginDate, endDate) => (
    <div className='booking-banner__container-offer-date-label'>
      <FormattedMessage id='pages.park.booking_banner.from' />
      <span className='text--medium-weight'> {beginDate.format('DD/MM HH:mm')} </span>
      <FormattedMessage id='pages.park.booking_banner.to' />
      <span className='text--medium-weight'> {endDate.format('DD/MM HH:mm')}</span>
    </div>
  )

  renderSubscriptionOfferDates = (beginDate) => (
    <div className='booking-banner__container-offer-date-label'>
      <FormattedMessage id='pages.homepage.search.subs_begindate' />
      <span className='text--medium-weight'> {beginDate.format('DD/MM')} </span>
    </div>
  )

  renderBestOffer = (bestOffer, onSitePrice, type) => (
    <div>
      {(onSitePrice && bestOffer.amount.ref)
        ? (
          <div>
            <div className='booking-banner__on-site-price row'>
              <div className='col-xs-12'>
                <div className='row'>
                  <div className='col-xs-12 booking-banner__on-site-price--title'>
                    <FormattedMessage id='pages.park.on_site_price' />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-12 booking-banner__on-site-price--ref-price'>
                    <FormattedNumber value={bestOffer.amount.ref || bestOffer.amount.totalWithoutOptions} style='currency' currency='EUR' />
                  </div>
                </div>
              </div>
            </div>

            <div className='m-top-4 row'>
              <div className='col-xs-4'>
                <span className='booking-banner__main-price'>
                  <FormattedNumber value={bestOffer.amount.totalWithoutOptions} style='currency' currency='EUR' />
                </span>

                {bestOffer.amountWithSecondaryCurrency && (
                  <span className='booking-banner__secondary-price'>
                    <FormattedNumber
                      value={bestOffer.amountWithSecondaryCurrency.total}
                      style='currency'
                      currency={bestOffer.amountWithSecondaryCurrency.unit} />
                  </span>
                )}
              </div>
            </div>
          </div>
        )
        : (
          <div>
            <div className='text--medium-weight'>
              {type === 'package' && bestOffer.title}
              {type === 'subscription' &&
              <FormattedMessage id='pages.park.subscription_offer_title' />
              }
            </div>

            <div className='m-top-4'>
              <span className={`booking-banner__main-price ${bestOffer.amount.ref ? ' booking-banner__main-price--promo' : ''}`}>
                <FormattedNumber value={bestOffer.amount.totalWithoutOptions} style='currency' currency='EUR' />
                {type === 'subscription' &&
                <FormattedMessage id='pages.park.frequency' />
                }
              </span>

              {bestOffer.amountWithSecondaryCurrency && (
                <span className='booking-banner__secondary-price'>
                  <FormattedNumber
                    value={bestOffer.amountWithSecondaryCurrency.total}
                    style='currency'
                    currency={bestOffer.amountWithSecondaryCurrency.unit} />
                  {type === 'subscription' &&
                  <FormattedMessage id='pages.park.frequency' />
                  }
                </span>
              )}
            </div>
          </div>
        )}
    </div>
  )
}

const mapStateToProps = ({
  bookingData: { bestOffer, noOfferReason, state },
  railsContext: {
    currentSite: { iso: country }
  },
  resources: { localePrefix, userSignedIn },
  search: { params: { type, beginDate, endDate } }
}) => ({
  beginDate,
  country,
  endDate,
  localePrefix,
  bestOffer,
  noOfferReason,
  userSignedIn,
  parkState: state,
  type
})

export default connect(mapStateToProps)(BookingBanner)
