import moment from 'moment'
import React from 'react'
import ReactDOM from 'react-dom'
import * as rs from 'jsrsasign'

import Login from '../Authentication/Connection/components/Login'
import OpI18n from '../components/OpI18n'

/*
 ** Get the locale prefix for URL
 */
export function localePrefix () {
  return this.props.railsContext.localePrefix
}

export function renderLoginModal (railsContext, source) {
  ReactDOM.render(
    (<OpI18n {...railsContext}>
      <Login railsContext={railsContext} source={source} />
    </OpI18n>),
    document.getElementById('signin_modal_v2_body'),
    () => $('#signin_modal_v2').modal()
  )
}

/*
 * When this key is present in the local storage, it means that the user is logged in.
 * access_token part is used for making requests, it is valid for 1 hour
 * refresh_token part is used to fetch a new access_token when it has expired.
 */
const USER_CREDS_KEY = 'AUTHORIZATION_TOKEN_CONFIG'

/*
 * When this key is present in the local storage, it means that the browser as a client
 * can fetch the new access/refresh tokens on behalf of the user
 */
const SERVICE_CREDS_KEY = 'CLIENT_CREDENTIALS_TOKEN_CONFIG'

const AUTH_API_HOST = process.env.IDENTITY_API_URL
const redirectUri = () => `${window.location.origin}/oauth2/callback`

const CLIENT_CREDENTIALS_GRANT_TYPE = 'client_credentials'
const AUTH_CODE_GRANT_TYPE = 'authorization_code'

/*
 * Sign out of identity service and remove auth credentials from localStorage
*/
export const handleLogOutClick = async (signOutLink) => {
  const authLogoutResponse = await fetch(`${AUTH_API_HOST}/logout`,
    { method: 'GET', credentials: 'include' })

  if (authLogoutResponse.ok) {
    localStorage.removeItem(USER_CREDS_KEY)
    Cookies.remove('token_owner')
    Cookies.remove('token_owner_id')
    await fetch(signOutLink, { method: 'DELETE' })
    location.reload(true)
  } else {
    throw new Error(`Error while signing out of identity service (HTTP status ${authLogoutResponse.status})`)
  }
}

/*
 * Decode access token to get user type and id
*/
export const decodeAccessToken = (token) => {
  const payload = rs.jws.JWS.parse(token).payloadPP
  const subject = JSON.parse(payload).sub
  return { tokenOwner: subject.token_owner, tokenOwnerId: subject.token_owner_id }
}

const storeTokensLocally = (tokens, tokensType) => {
  let key
  if (tokensType === AUTH_CODE_GRANT_TYPE) key = USER_CREDS_KEY
  if (tokensType === CLIENT_CREDENTIALS_GRANT_TYPE) key = SERVICE_CREDS_KEY
  if (key) localStorage.setItem(key, JSON.stringify(tokens))
}

/*
 * Get access tokens
*/
export const getAuthorizationTokens = async (grantType, code) => {
  let data = { grant_type: grantType }

  const clientCredentials = getClientCredentialsByDomain()
  if (!clientCredentials.client_id) return {}

  data = Object.assign(data, clientCredentials)

  if (code) {
    data.code = code
    data.redirect_uri = redirectUri()
  }

  const response = await fetch(`${AUTH_API_HOST}/oauth2/access_token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })

  if (!response.ok) {
    Sentry.captureException(new Error(`Something is wrong with OAUTH2 credentials: cannot get the new tokens, ${response.body}`))
    return {}
  }

  const tokens = await response.json()
  storeTokensLocally(tokens, grantType)
  return tokens
}

export const getLoginFormUrl = () => {
  return AUTH_API_HOST +
    `/oauth2/new-authorize?response_type=code&redirect_uri=${window.location.origin}/oauth2/callback&client_id=` +
    getClientCredentialsByDomain().client_id
}

/*
 * Get top level site domain (.fr or .co)
*/
export const getTopLevelDomain = () => {
  const originArr = window.location.origin.split('.')
  return originArr[originArr.length - 1].split(':')[0]
}

export const refreshToken = async (grantType) => {
  const storedTokenKey = grantType === CLIENT_CREDENTIALS_GRANT_TYPE ? SERVICE_CREDS_KEY : USER_CREDS_KEY
  let data = {
    grant_type: 'refresh_token',
    redirect_uri: `${window.location.origin}/oauth2/callback`,
    refresh_token: JSON.parse(localStorage.getItem(storedTokenKey)).refresh_token
  }
  data = Object.assign(data, getClientCredentialsByDomain())
  const response = await fetch(`${AUTH_API_HOST}/oauth2/access_token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })

  const locallyStoredTokens = JSON.parse(localStorage.getItem(storedTokenKey))
  let updatedTokens = null
  if (response.ok) {
    updatedTokens = await response.json()
  } else if (grantType === CLIENT_CREDENTIALS_GRANT_TYPE) {
    updatedTokens = await getAuthorizationTokens(grantType)
  } else {
    // Refresh token expired, log out the user
    localStorage.removeItem(USER_CREDS_KEY)
    return null
  }

  locallyStoredTokens.access_token = updatedTokens.access_token
  if (updatedTokens.refresh_token) locallyStoredTokens.refresh_token = updatedTokens.refresh_token
  storeTokensLocally(locallyStoredTokens, grantType)
  localStorage.setItem(storedTokenKey, JSON.stringify(locallyStoredTokens))

  return updatedTokens.access_token
}

/*
 * Refresh service access token
*/
export const refreshServiceToken = async () => {
  return refreshToken(CLIENT_CREDENTIALS_GRANT_TYPE)
}

/*
 * Refresh end-user's access token
*/
export const refreshUserToken = async () => {
  return refreshToken(AUTH_CODE_GRANT_TYPE)
}

/*
 * Set cookies for user type and id
*/
export const setUserDataCookies = (tokenOwner, tokenOwnerId) => {
  window.Cookies.set('token_owner', tokenOwner, { expires: new Date(moment().add(13, 'months').toDate()) })
  window.Cookies.set('token_owner_id', tokenOwnerId, { expires: new Date(moment().add(13, 'months').toDate()) })
}

/*
 * Get service A.K.A. 'client' tokens from localStorage or Identity Service
*/
export const getClientTokens = async () => {
  let clientTokens = JSON.parse(localStorage.getItem(SERVICE_CREDS_KEY))

  if (!clientTokens) {
    clientTokens = await getAuthorizationTokens(CLIENT_CREDENTIALS_GRANT_TYPE)
    if (Object.keys(clientTokens).length) storeTokensLocally(clientTokens, CLIENT_CREDENTIALS_GRANT_TYPE)
  }

  return clientTokens
}

/*
 * Get authorization token from localStorage
*/
export const getAuthToken = () => {
  const authTokens = JSON.parse(localStorage.getItem(USER_CREDS_KEY))

  if (authTokens) {
    return authTokens.access_token
  }
}

/*
 * Get client id and secret depending on the domain.
 * Not for export, should be kind of 'private'.
*/
const getClientCredentialsByDomain = () => {
  if (getTopLevelDomain() === 'co' && process.env.PORTAL_CLIENT_ID && process.env.PORTAL_CLIENT_SECRET) {
    return {
      client_id: process.env.PORTAL_CLIENT_ID,
      client_secret: process.env.PORTAL_CLIENT_SECRET
    }
  }

  if (getTopLevelDomain() === 'fr' && process.env.DEFAULT_CLIENT_ID && process.env.DEFAULT_CLIENT_SECRET) {
    return {
      client_id: process.env.DEFAULT_CLIENT_ID,
      client_secret: process.env.DEFAULT_CLIENT_SECRET
    }
  }

  Sentry.captureException(new Error('Something is wrong with OAUTH2 credentials: they are missing!'))
  return {}
}
