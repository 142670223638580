import imgVisa from 'Assets/images/svg/cards_visa.svg'
import imgMastercard from 'Assets/images/svg/cards_mastercard.svg'
import imgAmex from 'Assets/images/svg/cards_amex.svg'
import imgTotal from 'Assets/images/svg/cards_total.svg'

export const defaultPaymentMethods = ['visa', 'mastercard', 'amex', 'total']

export const paymentMethodsByCountry = {
  be: ['visa', 'mastercard', 'amex', 'total'],
  ch: ['visa', 'mastercard', 'amex', 'total'],
  de: ['visa', 'mastercard', 'amex', 'total'],
  es: ['visa', 'mastercard', 'amex', 'total'],
  fr: ['visa', 'mastercard', 'amex', 'total'],
  it: ['visa', 'mastercard', 'amex', 'total'],
  nl: ['visa', 'mastercard', 'amex', 'total'],
  pt: ['visa', 'mastercard', 'amex', 'total']
}

export const paymentIconByName = {
  mastercard: { src: imgMastercard, alt: 'Mastercard' },
  visa: { src: imgVisa, alt: 'Visa' },
  amex: { src: imgAmex, alt: 'American Express' },
  total: { src: imgTotal, alt: 'Total Energies' }
}
