import logoVisa from 'Assets/images/svg/cards_visa.svg'
import logoMastercard from 'Assets/images/svg/cards_mastercard.svg'
import logoCartBlancare from 'Assets/images/svg/cards_cb.svg'
import logoAmericanExpress from 'Assets/images/svg/cards_amex.svg'
import logoTotal from 'Assets/images/svg/cards_total.svg'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

export class NoUsablePaymentMethodsWarning extends Component {
  render () {
    const titleMessageId = this.props.totalPaymentMethodsArePresent
      ? 'purchase.purchase_summary.cannot_use_total.title'
      : 'purchase.purchase_summary.add_new_payment_method.title'
    const textMessageId = this.props.totalPaymentMethodsArePresent
      ? 'purchase.purchase_summary.cannot_use_total.text'
      : 'purchase.purchase_summary.add_new_payment_method.text'

    return (
      <div className='payment-method-no-card'>
        <div className='payment-method-no-card__text-wrapper'>
          <div className='payment-method-no-card__title'>
            <FormattedMessage id={titleMessageId} />
          </div>
          <div className='payment-method-no-card__main-text'>
            <FormattedMessage id={textMessageId} />
          </div>
        </div>
        <div className='payment-method-no-card__new-card-button-wrapper'>
          <button className='payment-method-no-card__new-card-button' onClick={this.props.onSubmit}>
            <FormattedMessage id='purchase.purchase_summary.add_new_payment_method.cta' />
          </button>
          <div className='payment-method-no-card__new-card-icons'>
            <img src={logoVisa} alt='logo for Visa credit card' />
            <img src={logoMastercard} alt='logo for Mastercard credit card' />
            <img src={logoAmericanExpress} alt='logo for American Express credit card' />
            <img src={logoCartBlancare} alt='logo for a BlanCare credit card' />
            <img src={logoTotal} alt='logo for TotalFleet credit card' />
          </div>
        </div>
      </div>
    )
  }
}
