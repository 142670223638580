import { refreshToken, getClientTokens } from './authentication'

export function defaultDeserializer (jsonResponse) {
  return jsonResponse.data.map(entry => transformKeysToCamelCase(entry.attributes))
}

export function idDeserializer (jsonResponse) { return jsonResponse.data.id }

export function singleEntryAttributes (jsonResponse) { return jsonResponse.data.attributes }

const toCamelCase = string => string.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())

export function transformKeysToCamelCase (object) {
  return Object.keys(object).reduce((newObject, key) => {
    newObject[toCamelCase(key)] = object[key]
    return newObject
  }, {})
}

const UNDEFINED_AUTH_CREDENTIALS_RESPONSE = { status: 403, body: 'Missing oauth credentials' }

// It makes an authorized HTTP request to microservices and returns the body.
// If the microservices responds with 401/403, it tries to refresh the access token
// and returns whatever is the result of that second request.
// It reports errors to Sentry when cannot fetch access tokens or status code is unexpected.
export async function sendRequestToApiRaw (backendUrl, fetchOpts = {}) {
  const reportError = (message) => {
    Sentry.withScope((scope) => {
      scope.setTag('url', backendUrl)
      Sentry.captureException(new Error(message))
    })
  }

  const clientCredentials = await getClientTokens()
  if (Object.keys(clientCredentials).length === 0) return UNDEFINED_AUTH_CREDENTIALS_RESPONSE

  const response = await fetch(backendUrl, { ...fetchOpts, headers: { 'Authorization': `Bearer ${clientCredentials.access_token}` } })
  switch (response.status) {
    case 403:
      reportError('Something is wrong with OAUTH credentials: UI gets 403 from backend')
      return response
    case 401:
      try {
        const freshAccessToken = await refreshToken('client_credentials')
        const responseRetried = await fetch(backendUrl, { ...fetchOpts, headers: { 'Authorization': `Bearer ${freshAccessToken}` } })
        if ([400, 404, 422, 500].includes(responseRetried.status)) {
          reportError(`Got unexpected status code from backend: ${responseRetried.status} and body ${responseRetried.body}`)
        }
        return responseRetried
      } catch (error) {
        reportError(error.message)
        return UNDEFINED_AUTH_CREDENTIALS_RESPONSE
      }
    case 400:
    case 404:
    case 422:
    case 500:
      Sentry.captureException(new Error(`Got unexpected status code from backend: ${response.status} and body ${response.body}`))
      return response
    default:
      return response
  }
}

// It makes an authorized HTTP request to microservices.
// If response is ok then we parse the json body, then deserialize it.
// Otherwise, we can do some callback with the response.
export async function sendRequestToApi (
  backendUrl, fetchOpts = {}, responseArraySerializer = defaultDeserializer, onError = (_response) => {}
) {
  try {
    const response = await sendRequestToApiRaw(backendUrl, fetchOpts)
    if (response.ok) {
      const responseJson = await response.json()
      return responseArraySerializer(responseJson)
    } else {
      onError(response)
      return []
    }
  } catch (_error) {
    onError()
    return []
  }
}
