import { StripeAddPaymentMethodPopup } from 'Components/StripeAddPaymentMethodPopup'
import Collapsible from 'react-collapsible'
import Loader from 'Components/Loader'
import PaymentInfoPanel from '../PaymentInfoPanel'
import React, { Component } from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { NoUsablePaymentMethodsWarning } from 'Components/NoUsablePaymentMethodsWarning'
import { SummaryPaymentMethodCard } from 'Components/PaymentMethodCard/SummaryPaymentMethodCard'
import { MonextAddPaymentMethodPopup } from 'Components/MonextAddPaymentMethodPopup'
import { connect } from 'react-redux'
import { emitGa4PageView } from '../../../../libs/google_analytics_helpers'
import { fetchPaymentMethods, transitionPaymentMethodToError } from 'Helpers/paymentMethodsHelper'

class PaymentSection extends Component {
  state = {
    paymentMethodsLoaded: false,
    paymentMethodsLoading: false,
    paymentMethods: [],
    paymentMethodsError: null,
    selectedPaymentMethodId: null,
    showWarningPaymentForm: false,
    monextWidgetToken: null,
    newPaymentMethodPreselected: null,
    newPaymentMethodErrorId: null,
    openedToolTipId: null
  }

  setOpenedToolTipId = (tooltipId) => this.setState({ openedToolTipId: tooltipId })

  async componentDidMount () {
    if (this.props.showPaymentForm) emitGa4PageView('cc_registration')
    if (!this.state.paymentMethodsLoaded) {
      await this.fetchPaymentMethods()
    }
  }

  enrichWithIsEnabled = (billingIdentity) => {
    return {
      ...billingIdentity,
      isEnabled: (billingIdentity.type === 'stripe-identity') || this.props.monextPaymentMethodAllowed
    }
  }

  async fetchPaymentMethods () {
    if (this.state.paymentMethodsLoading) return

    this.setState({ paymentMethodsLoading: true })
    let responseNotOk = false
    try {
      const fetchedValuesRaw = await fetchPaymentMethods({ onError: () => { responseNotOk = true } })
      const fetchedValues = fetchedValuesRaw.map(this.enrichWithIsEnabled)
      if (responseNotOk) {
        this.setState({ paymentMethodsLoaded: true, paymentMethodsError: 'purchase.purchase_summary.fetch_payment_methods_timed_out' })
        return
      }
      const validPaymentMethods = fetchedValues.filter(identity => identity.isEnabled)
      const selectedIdentity = validPaymentMethods.find(identity => identity.isDefault) || validPaymentMethods[0]
      if (selectedIdentity) this.setSelectedPaymentId(selectedIdentity.id, selectedIdentity.allowsThreeds)
      this.setState({
        paymentMethodsLoaded: true, paymentMethods: fetchedValues, showWarningPaymentForm: !selectedIdentity, paymentMethodsLoading: false
      })
    } catch (error) {
      this.setState({
        paymentMethodsLoaded: true, paymentMethodsError: 'purchase.purchase_summary.fetch_payment_methods_timed_out', paymentMethodsLoading: false
      })
    }
  }

  setSelectedPaymentId = (identityId, allows3ds) => {
    this.setState({ selectedPaymentMethodId: identityId })
    if (this.props.setSelectedPaymentId && identityId) this.props.setSelectedPaymentId(identityId, allows3ds)
  }

  componentDidUpdate (_previousProps, _previousState) {
    if (this.props.showPaymentForm) emitGa4PageView('cc_registration')
    if (!this.state.paymentMethodsLoaded) this.fetchPaymentMethods()
  }

  onPendingPaymentMethodSucceeded = async () => {
    this.setState({ paymentMethodsLoaded: false })
    this.props.showCardAddedNotification()
    await this.fetchPaymentMethods()
  }

  onMonextPendingPaymentMethodFailed = (errorMessageId) => {
    setTimeout(() => { this.setState({ newPaymentMethodPreselected: 'Monext', newPaymentMethodErrorId: errorMessageId }) }, 0)
    setTimeout(() => { this.props.handleShowPaymentForm(false) }, 0)
    setTimeout(() => { this.props.handleShowPaymentForm(true) }, 0)
  }

  onStripePendingPaymentMethodFailed = () => {
    this.props.toggleRegisterPaymentMethodTimedOut()
    this.setState({ newPaymentMethodPreselected: 'Stripe', newPaymentMethodErrorId: 'pending_payment_stopped_waiting' })
  }

  clearPendingPaymentMethodErrors = () => {
    this.setState({
      newPaymentMethodPreselected: null, newPaymentMethodErrorId: null, paymentMethodsError: null, monextWidgetToken: null
    })
  }

  onCancelShowForm = () => {
    this.hidePaymentForm()
    this.setState({ paymentMethodsLoaded: false })
    this.markMonextBillingIdentityAsError(this.state.monextWidgetToken)
  }

  hidePaymentForm = () => {
    this.clearPendingPaymentMethodErrors()
    this.props.handleShowPaymentForm(false)
  }

  showPaymentForm = () => {
    this.clearPendingPaymentMethodErrors()
    this.props.handleStripeError() // TODO: rename with a better name in scope of https://onepark.atlassian.net/browse/OPDEV-5637
    this.props.handleShowPaymentForm()
  }

  markMonextBillingIdentityAsError = (token) => {
    if (token) transitionPaymentMethodToError(token)
  }

  render () {
    const { showPaymentForm, forwardedRef, warning } = this.props
    const blockClassName = classNames('purchase-summary__block row', {
      'purchase-summary__block--orange': warning
    })
    const sectionTitleId = showPaymentForm
      ? 'purchase.purchase_summary.payment.title_open_form'
      : 'purchase.purchase_summary.payment.title'

    return (
      <div className={blockClassName} ref={forwardedRef}>

        {this.props.showPendingMethodPopup &&
          <MonextAddPaymentMethodPopup
            theme='green'
            pendingToken={this.props.paymentMethodTokenFromQuery}
            onSuccess={this.onPendingPaymentMethodSucceeded}
            onError={this.onMonextPendingPaymentMethodFailed}
          />
        }
        <StripeAddPaymentMethodPopup
          theme='green'
          onFetchSuccess={this.onPendingPaymentMethodSucceeded}
          onFetchTimeoutError={this.onStripePendingPaymentMethodFailed}
        />

        <FormattedMessage id={sectionTitleId}>
          {(message) => (
            <Collapsible trigger={message} open overflowWhenOpen='unset' contentInnerClassName='Collapsible__contentInner--narrow-bottom-margins' >
              { this.state.showWarningPaymentForm && !showPaymentForm &&
                <NoUsablePaymentMethodsWarning
                  onSubmit={this.showPaymentForm}
                  totalPaymentMethodsArePresent={this.state.paymentMethods.length > 0}
                />
              }

              {this.props.paymentErrorsSection && this.props.paymentErrorsSection()}

              { !showPaymentForm &&
                <>
                  <div className='purchase-summary__info-block purchase-summary__info-block--no-padding-top'>
                    {!this.paymentMethodsError && !this.state.paymentMethodsLoaded &&
                      <div className='purchase-summary__block--flex purchase-summary__block--bigger-svg purchase-summary__block--centered'>
                        <Loader green />
                      </div>}
                    {this.state.paymentMethodsError &&
                      <div className='purchase-summary__payment-method-error'>
                        <FormattedMessage id={this.state.paymentMethodsError} />
                      </div>}
                    {this.state.paymentMethodsLoaded && this.state.paymentMethods.map(paymentMethodProps => {
                      return <SummaryPaymentMethodCard
                        {...paymentMethodProps} key={paymentMethodProps.id} onSelect={this.setSelectedPaymentId}
                        onChange={this.props.handleStripeError}
                        openedToolTipId={this.state.openedToolTipId}
                        onToolTipOpened={this.setOpenedToolTipId}
                        selectedPaymentMethodId={this.state.selectedPaymentMethodId} />
                    })}
                  </div>
                  { !this.state.showWarningPaymentForm &&
                    <div className='purchase-summary__add-new-card-link' onClick={this.showPaymentForm}
                      data-automation-id='purchase-summary__payment-edit'>
                      {'+ '}
                      <FormattedMessage id='purchase.purchase_summary.add_payment_method_link' />
                    </div>
                  }
                </>
              }

              {showPaymentForm &&
                <>
                  <div className='purchase-summary__cancel-new-card-link purchase-summary__cancel-new-card-link--desktop'
                    onClick={this.onCancelShowForm}>
                    {'< '}
                    <FormattedMessage id='purchase.purchase_summary.close_payment_method_form' />
                  </div>
                  <PaymentInfoPanel
                    {...this.props}
                    callingPage='booking'
                    hideTitle
                    turnoffWarning={this.props.turnoffWarning}
                    handleStripeChange={this.props.handleStripeChange}
                    handleStripeError={this.props.handleStripeError}
                    initialSelection={this.state.newPaymentMethodPreselected}
                    errorMessageId={this.state.newPaymentMethodErrorId}
                    monextPaymentMethodAllowed={this.props.monextPaymentMethodAllowed}
                    onMonextWidgetTokenSet={(token) => { this.setState({ monextWidgetToken: token }) }}
                    onMonextError={this.onMonextPendingPaymentMethodFailed}
                    clearErrors={this.clearPendingPaymentMethodErrors}
                  />
                  <div className='purchase-summary__cancel-new-card-link purchase-summary__cancel-new-card-link--mobile'
                    onClick={this.onCancelShowForm}>
                    {'< '}
                    <FormattedMessage id='purchase.purchase_summary.close_payment_method_form' />
                  </div>
                </>
              }
            </Collapsible>
          )}
        </FormattedMessage>
      </div>
    )
  }
}

const mapStateToProps = ({
  purchase,
  resources: { localePrefix },
  railsContext: { location }
}) => ({ localePrefix, location, purchase })

export default connect(mapStateToProps)(PaymentSection)
