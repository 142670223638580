import SvgImage from 'Components/SvgImage'
import React, { Component } from 'react'
import { PENDING_PAYMENT_TOKEN_NAME } from '../Purchase/CommonSummary'
import PaymentInfoPanel from '../Purchase/PaymentInfoPanel'
import { FormattedHTMLMessage, injectIntl } from 'react-intl'
import { StripeAddPaymentMethodPopup } from './StripeAddPaymentMethodPopup'
import PropTypes from 'prop-types'
import { DEFAULT_BILLING_IDENTITY_FIELDS, deleteBillingIdentityById, fetchPaymentMethods, transitionPaymentMethodToError } from 'Helpers/paymentMethodsHelper'
import ProfilePaymentMethodCard from './PaymentMethodCard/ProfilePaymentMethodCard'
import closeCross from 'Assets/images/svg-on-react/icon-black-cross.svg'
import logoVisa from 'Assets/images/svg/cards_visa.svg'
import logoMastercard from 'Assets/images/svg/cards_mastercard.svg'
import logoTotal from 'Assets/images/svg/cards_total.svg'
import logoCartBlancare from 'Assets/images/svg/cards_cb.svg'
import logoAmericanExpress from 'Assets/images/svg/cards_amex.svg'
import Loader from './Loader'
import { MonextAddPaymentMethodPopup } from './MonextAddPaymentMethodPopup'
import { emitGa4PageView } from '../../../libs/google_analytics_helpers'
import DOMPurify from 'dompurify'

const USER_HAS_BOOKINGS_ERROR = 'user_has_bookings'

class ProfilePaymentMethodsList extends Component {
  static propTypes = {
    stripePublicKey: PropTypes.string
  }

  static storagePrefix = 'profile'

  paymentMethodTokenFromQuery = (new URL(window.location)).searchParams.get(PENDING_PAYMENT_TOKEN_NAME)

  monextRelatedStates = () => {
    const storagePrefix = this.constructor.storagePrefix
    const pendingPaymentMethodTokenIsPresentNotShown = !!(
      this.paymentMethodTokenFromQuery &&
      sessionStorage.getItem(`${storagePrefix}-pending-token-shown-${this.paymentMethodTokenFromQuery}`) !== 'true'
    )
    return {
      monextWidgetToken: null,
      newPaymentMethodPreselected: null,
      monextPaymentMethodErrorId: null,
      showMonextPendingPaymentMethodPopup: pendingPaymentMethodTokenIsPresentNotShown
    }
  }

  state = {
    ...this.monextRelatedStates(),
    showPaymentForm: false,
    deletionInProgress: false,
    fetchFailed: false,
    paymentMethodsLoading: false,
    paymentMethodsLoaded: false,
    infoAlertMessage: null,
    errorAlertMessage: null,
    paymentMethods: [],
    paymentMethodsRequireUpdate: false
  }

  async componentDidMount () {
    // To be discussed to push ga4 or not
    if (this.state.showPaymentForm) emitGa4PageView('cc_registration')
    await this.loadPaymentMethods()
    this.markPendingPaymentMethodTokenAsShown()
    this.onSuccessAfterRedirect()
  }

  async componentDidUpdate (_previousProps, previousState) {
    // To be discussed to push ga4 or not
    if (this.state.showPaymentForm) emitGa4PageView('cc_registration')
    if (!this.state.paymentMethodsLoaded) await this.loadPaymentMethods()
  }

  loadPaymentMethods = async () => {
    if (this.state.paymentMethodsLoading) return

    this.setState({ paymentMethodsLoading: true })
    let responseNotOk = false
    const fetchedValuesRaw = await fetchPaymentMethods({
      onError: () => { responseNotOk = true },
      fields: ['deletable', 'requires-update', ...DEFAULT_BILLING_IDENTITY_FIELDS]
    })
    if (responseNotOk) {
      this.setState({ paymentMethods: [], paymentMethodsLoaded: true, paymentMethodsLoading: false, fetchFailed: true })
      return
    }
    const paymentMethodsRequireUpdate = fetchedValuesRaw.some((paymentMethod) => paymentMethod.requiresUpdate)
    this.setState({
      paymentMethods: fetchedValuesRaw, paymentMethodsLoaded: true, paymentMethodsLoading: false,
      fetchFailed: false, paymentMethodsRequireUpdate
    })
  }

  markPendingPaymentMethodTokenAsShown = () => {
    if (this.paymentMethodTokenFromQuery) {
      this.protectFromReload(`pending-token-shown-${this.paymentMethodTokenFromQuery}`, 'true')
    }
  }

  protectFromReload = (name, value) => {
    if (window === undefined) return
    sessionStorage.setItem(`${this.constructor.storagePrefix}-${name}`, value.toString())
  }

  clearMonextRelatedStates = () => {
    this.setState({
      newPaymentMethodPreselected: null, monextPaymentMethodErrorId: null, monextWidgetToken: null
    })
  }

  showPaymentForm = () => {
    this.clearMonextRelatedStates()
    this.setState({ showPaymentForm: true, infoAlertMessage: null, errorAlertMessage: null, fetchFailed: false })
  }

  hidePaymentForm = () => {
    this.clearMonextRelatedStates()
    this.markMonextBillingIdentityAsError(this.state.monextWidgetToken)
    this.setState({ paymentMethodsLoaded: false, showPaymentForm: false, infoAlertMessage: null, errorAlertMessage: null, fetchFailed: false })
  }

  markMonextBillingIdentityAsError = (token) => {
    if (token) transitionPaymentMethodToError(token)
  }

  onRegisterPaymentMethodSuccess = () => {
    const infoAlertMessage = this.props.intl.formatMessage({ id: 'pages.credit_card.profile.alerts.saved' })

    this.setState({ infoAlertMessage, errorAlertMessage: null, paymentMethodsLoaded: false })
  }

  onSuccessAfterRedirect = () => {
    if ((new URL(window.location)).searchParams.get('registered') !== 'true') return

    this.setState({ infoAlertMessage: this.props.intl.formatMessage({ id: 'pages.credit_card.profile.alerts.saved' }) })
  }

  onMonextPendingPaymentMethodFailed = (errorMessageId) => {
    this.setState({ showPaymentForm: false },
      () => {
        this.setState({ newPaymentMethodPreselected: 'Monext', monextPaymentMethodErrorId: errorMessageId, showPaymentForm: true })
      }
    )
  }

  onStripePendingPaymentMethodFailed = () => {
    this.setState({
      infoAlertMessage: null, showPaymentForm: true,
      newPaymentMethodPreselected: 'Stripe', monextPaymentMethodErrorId: 'pending_payment_stopped_waiting'
    })
  }

  onPaymentMethodDelete = async ({ deletable, id }) => {
    const { intl: { formatMessage } } = this.props
    if (window.confirm(formatMessage({ id: 'pages.credit_card.profile.delete_confirm' }))) {
      if (!deletable) return this.showDeleteErrorMessage()

      let responseOk = true
      this.setState({ deletionInProgress: true })
      await deleteBillingIdentityById(id, (response) => {
        responseOk = false
        this.handleDeletePaymentMethodErrors(response)
      })
      if (responseOk) {
        const infoAlertMessage = formatMessage({ id: 'pages.credit_card.profile.alerts.deleted' })
        this.setState({ errorAlertMessage: null, infoAlertMessage, deletionInProgress: false, paymentMethodsLoaded: false })
      }
    }
  }

  handleDeletePaymentMethodErrors = async (response) => {
    const responseJson = await response.json()
    if (responseJson && responseJson.errors) {
      responseJson.errors.some(({ code }) => {
        if (code === USER_HAS_BOOKINGS_ERROR) {
          this.showDeleteErrorMessage()
          return true
        }
      })
    }
  }

  showDeleteErrorMessage = () => {
    const { intl: { formatMessage } } = this.props
    const sanitizedDeleteErrorMessage = DOMPurify.sanitize(
      formatMessage({ id: 'pages.credit_card.profile.alerts.delete_error' }), { ALLOWED_TAGS: ['br'], ALLOWED_ATTR: [] }
    )
    this.setState({ errorAlertMessage: sanitizedDeleteErrorMessage })
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth', block: 'start' })
  }

  renderErrorAlert () {
    return (
      <div className='alert alert-warning alert-dismissible alert-event' role='alert' data-action='warning' data-spy='affix' data-offset-top='80'>
        <button className='close' type='button' onClick={this.closeErrorAlert}>
          <span aria-hidden='true'>&times;</span>
        </button>
        <div className='message'>
          <p dangerouslySetInnerHTML={{ __html: this.state.errorAlertMessage }} />
        </div>
      </div>
    )
  }

  closeErrorAlert = () => this.setState({ errorAlertMessage: null })

  renderInfoAlert () {
    return (
      <div className='alert alert-info alert-dismissible alert-event' role='alert' data-action='notice' data-spy='affix' data-offset-top='80'>
        <button className='close' type='button' onClick={this.closeInfoAlert}>
          <span aria-hidden='true'>&times;</span>
        </button>
        <div className='message'>
          <p>{this.state.infoAlertMessage}</p>
        </div>
      </div>
    )
  }

  closeInfoAlert = () => this.setState({ infoAlertMessage: null })

  paymentMethodsRequireUpdateMessage = () => {
    if (!this.state.paymentMethodsRequireUpdate) return null

    return (
      <div className='profile-payment-methods-list__requires-update-warning-wrapper'>
        <FormattedHTMLMessage id='pages.credit_card.profile.requires_update_warning'>
          {(message) =>
            <span
              className='profile-payment-methods-list__requires-update-warning-text'
              dangerouslySetInnerHTML={{ __html: message }}
            />
          }
        </FormattedHTMLMessage>

        <div className='profile-payment-methods-list__requires-update-warning-close'
          onClick={() => this.setState({ paymentMethodsRequireUpdate: false })}>
          <SvgImage svg={closeCross} />
        </div>
      </div>
    )
  }

  clearPendingPaymentMethodErrors = () => this.setState({ monextPaymentMethodErrorId: null })

  render () {
    const { showPaymentForm, errorAlertMessage, infoAlertMessage, paymentMethods, paymentMethodsLoading, fetchFailed } = this.state
    const { intl: { formatMessage }, stripePublicKey } = this.props
    const hasPaymentMethods = paymentMethods && !!paymentMethods.length

    return (
      <div className='profile-payment-methods-list'>
        <StripeAddPaymentMethodPopup
          theme='blue'
          onFetchSuccess={this.onRegisterPaymentMethodSuccess}
          onFetchTimeoutError={this.onStripePendingPaymentMethodFailed}
        />

        {this.state.showMonextPendingPaymentMethodPopup &&
          <MonextAddPaymentMethodPopup
            theme='blue'
            pendingToken={this.paymentMethodTokenFromQuery}
            onSuccess={this.onRegisterPaymentMethodSuccess}
            onError={this.onMonextPendingPaymentMethodFailed}
          />
        }

        {errorAlertMessage && this.renderErrorAlert()}
        {infoAlertMessage && this.renderInfoAlert()}

        {showPaymentForm && (
          <div>
            <span
              className='profile-payment-methods-list__return-to-list-button profile-payment-methods-list__return-to-list-button--desktop'
              onClick={this.hidePaymentForm}
            >
              {formatMessage({ id: 'pages.credit_card.profile.actions.back' })}
            </span>
            <div className='box box-shadow-custom profile-payment-methods-list__forms-container'>
              <h3>{formatMessage({ id: 'pages.credit_card.profile.add_form_title' })}</h3>
              <PaymentInfoPanel
                monextPaymentMethodAllowed
                onMonextWidgetTokenSet={(token) => { this.setState({ monextWidgetToken: token }) }}
                onMonextError={this.onMonextPendingPaymentMethodFailed}
                initialSelection={this.state.newPaymentMethodPreselected}
                errorMessageId={this.state.monextPaymentMethodErrorId}
                stripePublicKey={stripePublicKey}
                callingPage='profile'
                hideTitle
                clearErrors={this.clearPendingPaymentMethodErrors}
              />
            </div>
            <span
              className='profile-payment-methods-list__return-to-list-button profile-payment-methods-list__return-to-list-button--mobile'
              onClick={this.hidePaymentForm}
            >
              {formatMessage({ id: 'pages.credit_card.profile.actions.back' })}
            </span>
          </div>
        )}
        {!showPaymentForm && (
          <div>
            {this.paymentMethodsRequireUpdateMessage()}

            <div className='profile-payment-methods-list__header'>
              <span className='profile-payment-methods-list__title'>{formatMessage({ id: 'pages.credit_card.profile.title' })}</span>
              {(hasPaymentMethods || fetchFailed) &&
                <div className='profile-payment-methods-list__add-payment-method-button' onClick={this.showPaymentForm}>
                  <span>{formatMessage({ id: 'pages.credit_card.profile.actions.add_new_payment_method' })}</span>
                </div>}
            </div>
            <div className='profile-payment-methods-list__items-container'>
              {paymentMethodsLoading && (
                <div className='profile-payment-methods-list__spinner-container'>
                  <Loader blue />
                </div>
              )}
              {!paymentMethodsLoading && fetchFailed && (
                <div className='profile-payment-methods-list__fetch-failed-message-container'>
                  <span>{formatMessage({ id: 'pages.credit_card.profile.fetch_error_message' })}</span>
                </div>
              )}
              {!paymentMethodsLoading && !fetchFailed && paymentMethods && paymentMethods.map((paymentMethod) => (
                <ProfilePaymentMethodCard
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  onDelete={this.onPaymentMethodDelete}
                />
              ))}
            </div>
            {!paymentMethodsLoading && !fetchFailed && !paymentMethods.length && (
              <div className='box box-shadow-custom profile-payment-methods-list__no-payment-method-block'>
                <span>{formatMessage({ id: 'pages.credit_card.profile.no_card' })}</span>
                <button className='o-btn o-btn--primary' onClick={this.showPaymentForm}>
                  {formatMessage({ id: 'pages.credit_card.profile.actions.add_my_payment_method' })}
                </button>
                <div className='profile-payment-methods-list__brands'>
                  <img src={logoVisa} alt='logo for Visa credit card' />
                  <img src={logoMastercard} alt='logo for Mastercard credit card' />
                  <img src={logoTotal} alt='logo for TotalFleet credit card' />
                  <img src={logoCartBlancare} alt='logo for a BlanCare credit card' />
                  <img src={logoAmericanExpress} alt='logo for American Express credit card' />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(ProfilePaymentMethodsList)
